/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/**
 * @description Sorts the product skus by name
 * @param { string } deliveryType FPT or GPT
 * @param { [{ name: string, value: number }] } productSkus
 * @returns { [{ name: string, value: number }] }
 */

import getPrice from '../getPrice/getPrice';

const sortSkus = (deliveryType, productSkus) => {
    const output = productSkus.map((elem) => elem);
    if (deliveryType === 'FPT') {
        const skuHierarchy = {
            Small: 1,
            Medium: 2,
            Large: 3,
            'Extra Large': 4,
            Deluxe: 5,
            Premium: 6,
            'Half Dozen': 7,
            'Full Dozen': 8,
            'Two Dozen': 9,
        };
        const descendingByName = (a, b) => (skuHierarchy[a.name] < skuHierarchy[b.name] ? 1 : -1);
        output.sort(descendingByName);
        return output;
    }
    // GPT
    const descendingByPrice = (a, b) => {
        const priceA = getPrice(a.prices);
        const priceB = getPrice(b.prices);

        // always use retail value
        let valueA = priceA.value;
        if (priceA.hasSale) valueA = priceA.downFrom;

        let valueB = priceB.value;
        if (priceB.hasSale) valueB = priceB.downFrom;

        return valueA > valueB ? -1 : 1;
    };

    output.sort(descendingByPrice);
    return output;
};
export default sortSkus;
