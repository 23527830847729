/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bool, object, node } from 'prop-types';
import LazyLoad from 'react-lazyload';
// redux
import { getIsBot } from '../../../../../state/ducks/App/App-Selectors';

const LazyLoadWrapper = ({ isBot, children, config }) => {
    if (isBot) {
        return children;
    }
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <LazyLoad {...config}>
            {children}
        </LazyLoad>
    );
};

LazyLoadWrapper.propTypes = {
    isBot: bool,
    config: object,
    children: node.isRequired,
};

LazyLoadWrapper.defaultProps = {
    isBot: false,
    config: {},
};

const mapStateToProps = (state) => ({
    isBot: getIsBot(state),
});

const enhance = compose(
    connect(mapStateToProps, null),
);

export default enhance(LazyLoadWrapper);
