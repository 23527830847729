/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    Grid, Typography, Button, FormControl, Select, MenuItem, CircularProgress,
} from '@material-ui/core';

import { KeyboardArrowDown } from '@material-ui/icons';

import {
    object, string, shape, arrayOf, func, number,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import getPrice from '../../../../../../helpers/getPrice/getPrice';
import sortSkus from '../../../../../../helpers/sortSku/sortSku';

const useStyle = makeStyles((theme) => ({

    /**
     *
     * Begin button styles
     *
     */
    ProductErrorMessage: {
        color: 'red',
    },

    QuickviewButtonSelectorContainer: {
        marginBottom: '1.25em',

        [theme.breakpoints.down(1015)]: {
            marginBottom: '.5em',
        },
    },

    QuickviewProductButtonSelectors: {
        textTransform: 'none',
        border: '1.5px solid #dbdbdb',
        width: '150px',
        margin: '.5em',

        '&--is-selected': {
            boxShadow: '0px 0px 0px 1.5px rgb(101 56 139)',
        },

        '&:hover': {
            backgroundColor: 'transparent',
            cursour: 'pointer',
            boxShadow: '0px 0px 0px 1px rgb(101 56 139)',
        },
    },

    /**
     *
     * Begin dropdown styles
     *
     */

    FormControlRoot: {
        marginBottom: '1.25em',
        backgroundColor: 'transparent',
    },

    DropdownSelectorRootDefault: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: '4px 0',
        paddingRight: '48px !important', // same as width of icon
        paddingLeft: '12px',
        textAlign: 'center',
        overflowX: 'hidden',

        '&:focus': {
            backgroundColor: 'transparent',
        },
    },

    DropdownSelectorRootSelected: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '4px 0',
        paddingRight: '48px !important', // same as width of icon
        paddingLeft: '12px',
        textAlign: 'center',
        overflowX: 'hidden',

        '&:focus': {
            backgroundColor: 'transparent',
        },
    },

    DropdownSelectorIcon: {
        backgroundColor: '#cbcbcb',
        top: 'unset',
        right: '0',
        height: '100%',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        width: '48px',
    },

    QuickviewSelectorItem: {
        textAlign: 'center',
    },

    DropdownItemRoot: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

    PriceDisplay: {
        margin: '0 8px',
        color: 'grey',

        '&--strikethrough': {
            textDecoration: 'line-through',
            color: theme.palette.common.red,
        },

        '&--sale-price': {
            color: theme.palette.primaryButton,
        },
    },
    CircularProgress: {
        textAlign: 'center',
        margin: '0 auto',
    },
    skuName: {
        width: '100%',
    },
}));

const ProductSelectors = (props) => {
    const {
        deliveryType,
        productSkus,
        selected,
        setSelected,
        productSelectorInitialState,
        dynamicPriceAttributes,
        error,
        errorTypes,
        resolveError,
    } = props;

    const classes = useStyle();

    const hasError = error[errorTypes.MISSING_PRODUCT];

    let dropdownRootClass = classes.DropdownSelectorRootDefault;
    if (selected.id !== productSelectorInitialState.id) {
        dropdownRootClass = classes.DropdownSelectorRootSelected;
    }

    const {
        dynamicPriceIsLoading, productSkuPrices, priceRule,
    } = dynamicPriceAttributes;

    if (priceRule) {
        selected.priceRules = priceRule;
        selected.prices = productSkuPrices?.find?.((item) => item.id === selected.id)?.prices || selected.prices;
    } else {
        selected.priceRules = []; // clearing price rule
    }

    if (dynamicPriceIsLoading) {
        return <CircularProgress className={classes.CircularProgress} />;
    }
    return (
        <>
            {hasError
                && (
                    <Typography
                        className={classes.ProductErrorMessage}
                        variant="body2"
                    >
                        Please select a product
                    </Typography>
                )}

            {deliveryType === 'FPT' && productSkus?.length <= 3 && (
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.QuickviewButtonSelectorContainer}
                >
                    {sortSkus(deliveryType, productSkus).map((skuData) => {
                        const sku = skuData;
                        if (priceRule) {
                            sku.priceRules = priceRule;
                            sku.prices = productSkuPrices?.find?.((item) => item.id === skuData.id)?.prices || sku.prices;
                        } else {
                            sku.priceRules = []; // clearing price rule
                        }

                        let ariaPressed = false;
                        let buttonClassName = `${classes.QuickviewProductButtonSelectors}`;
                        if (sku.id === selected.id) {
                            ariaPressed = true;
                            buttonClassName += ` ${classes.QuickviewProductButtonSelectors}--is-selected`;
                        }

                        const price = getPrice(sku.prices);

                        let priceClassName = `${classes.PriceDisplay}`;
                        if (price.hasSale) {
                            priceClassName += ` ${classes.PriceDisplay}--sale-price`;
                        }

                        return (
                            <Button
                                role="button"
                                aria-pressed={ariaPressed}
                                disableRipple
                                disableFocusRipple
                                disableElevation
                                key={sku.id}
                                className={buttonClassName}
                                onClick={() => {
                                    if (error) resolveError(errorTypes.MISSING_PRODUCT);
                                    setSelected(sku);
                                }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                >

                                    <Typography
                                        variant="body2"
                                        className={classes.QuickviewPriceName}
                                    >
                                        {sku.name}
                                    </Typography>

                                    <Typography
                                        variant="body2"
                                        className={classes.QuickviewPriceValue}
                                    >
                                        <span>
                                            {price.hasSale && (
                                                <span className={`${classes.PriceDisplay}--strikethrough`}>
                                                    {price.displayValue(price.downFrom)}
                                                </span>
                                            )}
                                            <span className={priceClassName}>
                                                {price.displayValue()}
                                            </span>
                                        </span>
                                    </Typography>

                                </Grid>

                            </Button>
                        );
                    })}
                </Grid>
            )}

            {(deliveryType === 'GPT' || (deliveryType === 'FPT' && productSkus?.length > 3)) && (
                <FormControl
                    fullWidth
                    hiddenLabel
                    size="small"
                    margin="dense"
                    variant="outlined"
                    classes={{ root: classes.FormControlRoot }}
                >
                    <Select
                        value={selected} // the value here needs to match a MenuItem value
                        onChange={(event) => {
                            if (error[errorTypes.MISSING_PRODUCT]) resolveError(errorTypes.MISSING_PRODUCT);
                            setSelected(event.target.value);
                        }}
                        classes={{
                            root: dropdownRootClass,
                            icon: classes.DropdownSelectorIcon,
                        }}
                        IconComponent={KeyboardArrowDown}
                        renderValue={() => {
                            const price = getPrice(selected.prices);
                            let priceClassName = `${classes.PriceDisplay}`;
                            if (price.hasSale) {
                                priceClassName += ` ${classes.PriceDisplay}--sale-price`;
                            }
                            return (
                                <>
                                    <span>{selected.name}</span>
                                    <span>
                                        {price.hasSale && (
                                            <span className={`${classes.PriceDisplay}--strikethrough`}>
                                                {price.displayValue(price.downFrom)}
                                            </span>
                                        )}
                                        <span className={priceClassName}>
                                            {price.displayValue()}
                                        </span>
                                    </span>
                                </>
                            );
                        }}
                    >
                        {sortSkus(deliveryType, productSkus).map((skuData) => {
                            const sku = JSON.parse(JSON.stringify(skuData));
                            if (priceRule) {
                                sku.priceRules = priceRule;
                                sku.prices = productSkuPrices?.find?.((item) => item.id === skuData.id)?.prices || sku.prices;
                            } else {
                                sku.priceRules = []; // clearing price rule
                            }

                            const price = getPrice(sku.prices);

                            let priceClassName = `${classes.PriceDisplay}`;
                            if (price.hasSale) {
                                priceClassName += ` ${classes.PriceDisplay}--sale-price`;
                            }

                            return (
                                <MenuItem
                                    role="option"
                                    key={sku.id}
                                    value={sku}
                                    classes={{
                                        root: classes.DropdownItemRoot,
                                    }}
                                >
                                    <span className={classes.skuName}>{sku.name}</span>
                                    <span>
                                        {price.hasSale && (
                                            <span className={`${classes.PriceDisplay}--strikethrough`}>
                                                {price.displayValue(price.downFrom)}
                                            </span>
                                        )}
                                        <span className={priceClassName}>
                                            {price.displayValue()}
                                        </span>
                                    </span>
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            )}

        </>
    );
};

ProductSelectors.propTypes = {
    deliveryType: string.isRequired,

    productSkus: arrayOf(shape({
        id: string.isRequired,
        name: string.isRequired,
        prices: arrayOf(shape({
            type: string,
            value: number,
        })),
    })).isRequired,

    selected: shape({
        id: string.isRequired,
    }).isRequired,
    setSelected: func.isRequired,
    productSelectorInitialState: object.isRequired,

    error: object.isRequired,
    resolveError: func.isRequired,
    dynamicPriceAttributes: object.isRequired,
    errorTypes: object.isRequired,
};

ProductSelectors.defaultProps = {
};

export default ProductSelectors;
