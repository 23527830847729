/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const getTimeZoneAbbreviation = () => {
    // returns time zone abbreviation ie. EST / PST / EDT
    const dateSplitArray = new Date().toLocaleString('en-US', { timeZoneName: 'short' }).split(' ');
    return dateSplitArray[dateSplitArray.length - 1];
};

// eslint-disable-next-line func-names
export const getEarliestDeliveryDate = function (wcEnv, jwtToken, sku, zipCode, brandId) {
    const currentTimeZoneAbbreviation = getTimeZoneAbbreviation();

    const RESOURCE_PAGE_FACADE = '/getEarliestDeliveryDate';
    const RESOURCE_ROOT_FACADE = '/salta/v2/product-availability';
    const RESOURCE_PAGE_ACTION = `/?productCode=${sku}&brandId=${brandId}&zipCode=${zipCode}&timeZone=${currentTimeZoneAbbreviation}`;

    // eslint-disable-next-line no-param-reassign
    wcEnv.rootUri = RESOURCE_ROOT_FACADE;
    const resourcePage = RESOURCE_PAGE_FACADE + RESOURCE_PAGE_ACTION;
    return restClient.getFacade(wcEnv, resourcePage, jwtToken)
        .then((response) => response);
};

export default {};
