/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const getBrandFromPartNumber = (partNumber) => {
    if (partNumber.includes('-')) {
        return partNumber.split('-')[0];
    }
    return partNumber.split('_')[0];
};

export default {};
