/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// @intention: Check URL paramater to see if / exists
// @param: {string} product url
// @return: {string}
const determineProductUrl = (url) => {
    if (url?.[0] === '/') {
        return url;
    }
    return `/${url}`;
};

export default determineProductUrl;
