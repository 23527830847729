/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState, useContext, useEffect } from 'react';
import {
    Fade, Grid, Typography, Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactHTMLParser from 'react-html-parser';

import {
    bool, object, func, string, shape,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import GraphqlPortalLoader from '../../../../GraphqlCommonComponents/GraphqlPortalLoader';
import ProductSelectors from './ProductSelectors';
import DeliverySelectors from './DeliverySelectors';
import noop from '../../../../../../helpers/noop';

import ResponsiveImage from '../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import sortSkus from '../../../../../../helpers/sortSku/sortSku';

import { getEnterpriseId } from '../../../../../../../state/ducks/Member/ducks/EnterpriseId/EnterpriseId-Selectors';
import { getIsBot } from '../../../../../../../state/ducks/App/App-Selectors';
import { getFeatureFlags, getPresentationFamily } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import DynamicPrice from '../../../../GraphqlProductPage/Partials/GraphqlPDPSkus/DynamicPrice';
import { CrossMerch } from '../../../../CrossMerchContainer/CrossMerchContainer';
import { getAddToCartError } from '../../../../../../../state/ducks/AddToCart/AddToCart-Selectors';

const useStyle = makeStyles((theme) => ({
    DialogGridContainer: {
        height: '100%',
        [theme.breakpoints.down(1015)]: {
            justifyContent: 'center',
        },
    },

    DialogGridLeftSide: {
        maxWidth: '40%',
        minWidth: '345px',
        paddingRight: '20px',
        [theme.breakpoints.down(1023)]: {
            minWidth: 'auto',
        },
        [theme.breakpoints.down(767)]: {
            maxWidth: '100%',
            paddingRight: '0px',
            minWidth: '345px',
        },
    },

    DialogGridRightSide: {
        maxWidth: '60%',
        [theme.breakpoints.down(767)]: {
            marginLeft: '0',
            maxWidth: '100%',
        },
        '& .MuiOutlinedInput-input': {
            '& > span:first-child': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
    },

    QuickviewImage: {
        marginBottom: '8px',
        borderRadius: 0,
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',

        width: '345px',
        height: '378px',
        minWidth: '345px',
        minHeight: '378px',
        background: 'linear-gradient(270deg, #E7EDF1, #F2F8FC, #E7EDF1)',
        animation: '$shimmer 3.4s ease infinite',
        [theme.breakpoints.down(1023)]: {
            minWidth: 'auto',
            minHeight: 'auto',
            height: 'auto',
            width: '100%',
        },
        [theme.breakpoints.down(767)]: {
            width: '345px',
            height: '378px',
        },
    },

    QuickviewProductName: {
        marginBottom: '.8em',
        [theme.breakpoints.down(1015)]: {
            marginBottom: 'unset',
            margin: '0 auto',
        },
    },

    DeliveryDestinationText: {
        [theme.breakpoints.down(1015)]: {
            margin: '0 auto',
        },
    },

    QuickviewLinebreak: {
        width: '100%',
        borderTop: '1px solid #dbdbdb',
        borderLeft: 'none',
        borderBottom: 'none',
        marginBottom: '1em',
    },

    QuickviewAddToCart: {
        textTransform: 'none',
        width: '100%',
        margin: '12px 0',
        background: theme.palette.continueShoppingBg,
        color: theme.palette.common.white,
        '&:hover': {
            background: theme.palette.continueShoppingHoverBg,
        },

        [theme.breakpoints.down(1015)]: {
            width: '85%',
        },
    },

    QuickviewAddToCartDisabled: {
        textTransform: 'none',
        width: '100%',
        margin: '12px 0',
        background: theme.palette.disableButton,
        color: theme.palette.common.white,
        [theme.breakpoints.down(1015)]: {
            width: '65%',
        },
    },

    QuickviewLink: {
        fontSize: '.8em',
        paddingBottom: 'none',
        [theme.breakpoints.down(1015)]: {
            paddingBottom: '40px',
        },
    },

    error: {
        fontSize: '12px',
        paddingBottom: '5px',
        textAlign: 'center',
        color: 'red !important',
    },
}));

// define outside to keep from re-initializing
// keep as references so it can be passed to dropdown selector
const locationTypeOptionsDefault = [{ name: 'Residence' }, { name: 'Business' }, { name: 'Funeral home' }, { name: 'Hospital' }, { name: 'Apartment' }, { name: 'School' }, { name: 'Church' }];
const productSelectorInitialState = { id: 'no selection', name: 'Select an Option' };
const zipcodeInitialState = '';
const errorInitialState = {};
const errorTypes = {
    // if error is present, error should be an array of at least one
    MISSING_PRODUCT: 'missing product',
    MISSING_OR_INVALID_ZIPCODE: 'missing zipcode',
    MISSING_ZIPCODE: 'enter zipcode',
    MISSING_DELIVERY_DATE: 'missing delivery date',
};

const calendarStatusStates = {
    LOADING: 1,
    DONE: 2,
    NULL: null,
};
const dateInitialState = 'Select Date';
const addToCartInitialState = {};

const ageDetailsInitialState = {
    show: false,
    hasResponded: false,
    birthDay: '',
    birthMonth: '',
    birthYear: '',
    minAge: '0',
};

const QuickviewDialogContent = (props) => {
    const {
        data,
        reduxLocationType,
        isProductFilterZipcodeValid,
        clearValidatedZipcode,
        validateProductFilterZipcode,
        actionAddToCart,
        linkPackage,
        setBrandMovieSkus,
        trackEvent,
        userSubmittedProductFilterZipcode,
        enterpriseId,
        isBot,
        addToCartError,
        currentBrandFeatureFlags,
        categoryName: attributeCategoryName,
        categoryPath: attributeCategoryPath,
        categoryId: attributeCategoryId,
    } = props;

    const classes = useStyle();
    const {
        name,
        productSkus: productSkusData,
        availability,
        image,
        brandId,
        partNumber,
    } = data.findProductPageByPartNumber.product;
    /**
     * reconcile the locationType options.
     *
     * we might recieve the options from the api data
     * and if we dont, use locally defined options.
     */
    let locationTypeOptions = locationTypeOptionsDefault;

    const hasLocationTypeOptions = data?.findProductPageByPartNumber?.defaultContent?.entries?.[0]?.delivery_section?.location_type_options?.length;
    if (hasLocationTypeOptions) {
        locationTypeOptions = data.findProductPageByPartNumber.defaultContent.entries[0].delivery_section.location_type_options;
    }

    /**
    * locationType for the calendar is taken from redux and not
    * passed down from parents. Because of that, ensure the default
    * redux sate is reflected here.
    */
    const defaultLocation = locationTypeOptions.find((elem) => elem.name === reduxLocationType);
    let zipcodePrepopulate = zipcodeInitialState;
    if (userSubmittedProductFilterZipcode !== '') {
        zipcodePrepopulate = userSubmittedProductFilterZipcode;
    }
    const productSkus = JSON.parse(JSON.stringify(productSkusData)); // to avoid object extensible error!
    const productSku = sortSkus(availability.deliveryType, productSkus);
    const [selected, setSelected] = useState(productSku[0]); // product selector
    const [zipcode, setZipcode] = useState(zipcodePrepopulate); // zipcode input field
    const [locationType, setLocationType] = useState(defaultLocation); // location dropdown
    const [error, setError] = useState(errorInitialState); // error within quickview component
    const [calendarStatus, setCalendarStatus] = useState(calendarStatusStates.NULL); // calendar loading status, show/hide graphql modal spinner
    const [date, setDate] = useState(dateInitialState); // display value of date
    const [addToCartData, setAddToCartData] = useState(addToCartInitialState); // captured addToCart data for button press
    const [ageDetails, setAgeDetails] = useState(ageDetailsInitialState); // to pass to the calendar for the req object
    const [showProductUnavailable, setShowProductUnavailable] = useState(false);

    const { featureFlags: productBrandsFeatureFlags = {} } = useContext(CrossMerch);

    const [dynamicPriceIsLoading, setDynamicPriceLoading] = useState(false);
    const [productSkuPrices, setProductSkuPrices] = useState([]);
    const [priceRule, setPriceRule] = useState(null);

    const featureFlags = Object.keys(productBrandsFeatureFlags)?.length > 0 ? productBrandsFeatureFlags : currentBrandFeatureFlags;
    const isDynamicPriceEnabled = featureFlags['is-dynamic-pricing-enabled'];

    // declaring Dynamic price call instance
    const dynamicPriceInstance = new DynamicPrice(productSkus, enterpriseId, undefined, undefined, userSubmittedProductFilterZipcode);
    const {
        getPriceRules, getRetailPrice, getSalePrice,
    } = dynamicPriceInstance;

    const dynamicPriceAttributes = {
        getRetailPrice, getSalePrice, dynamicPriceIsLoading, productSkuPrices, priceRule,
    };
    useEffect(() => {
        if (isBot || !isDynamicPriceEnabled) {
            setDynamicPriceLoading(false);
            return;
        }

        const useZipcode = featureFlags['is-zip-dynamic-pricing-enabled'];

        setDynamicPriceLoading(true);
        dynamicPriceInstance.call(useZipcode, userSubmittedProductFilterZipcode).then((productPrices) => {
            const priceRules = getPriceRules(productSku?.[0]?.id);
            setPriceRule(priceRules);
            setProductSkuPrices(productPrices || []);
            setDynamicPriceLoading(false);
        }).catch(() => {
            setDynamicPriceLoading(false);
        });
    }, [
        setDynamicPriceLoading,
        setProductSkuPrices,
        featureFlags,
        userSubmittedProductFilterZipcode,
    ]);

    // handle error occured while add to cart
    useEffect(() => {
        if (addToCartError) setCalendarStatus(calendarStatusStates.NULL);
    }, [addToCartError]);

    /**
     * Once the Dialog is open, we need to identify if the selected sku
     * is a movie. If it is, set it in redux.
     */
    const isStandAloneMovieSku = productSkus.find(
        (elem) => (
            elem.parentProduct?.categories?.includes('digital_products')
            || elem.name?.toLowerCase()?.includes('digital movie gift')
        ),
    );
    if (isStandAloneMovieSku) {
        const movieSkus = productSkus?.reduce((acc, sku) => {
            const { id } = sku;
            // Starting at the end of the string (backwards pattern matching), continue to match any characters that aren't '-' or '_'
            return { ...acc, [id.match(/[^-_]*$/)]: true };
        }, {});
        setBrandMovieSkus(movieSkus);
    }

    /**
     * @description set errors for quickview
     * Takes one or multiple of the errorTypes: MISSING_OR_INVALID_ZIPCODE || MISSING_PRODUCT
     * @param {string | [string]} errorToAccount single or array or errorTypes
     */
    const accountError = (errorToAccount) => {
        const newErrors = { ...error };

        if (!Array.isArray(errorToAccount)) {
            newErrors[errorToAccount] = true;
            setError(newErrors);
            return;
        }

        errorToAccount.forEach((err) => {
            newErrors[err] = true;
        });
        setError(newErrors);
    };

    /**
     * @description Resolve errorTypes ephemeral state in a central location.
     * Takes one of the errorTypes: MISSING_OR_INVALID_ZIPCODE || MISSING_PRODUCT
     * @param {string} errorToResolve
     */
    const resolveError = (errorToResolve) => {
        const remainingErrors = { ...error };
        delete remainingErrors[errorToResolve];

        setError(remainingErrors);
    };

    /**
     * @description passed down to manipulate calendarStatus boolean
     * @note In its current implementation, setCalendarDataLoadingFlag is only ever
     * called with the parameter set to false. It should be set to true by the calendars
     * parent, in this case: DeliverySelectors.handleCalendarClick.
     * @param {boolean} isLoading
     */
    const setCalendarDataLoadingFlag = (isLoading) => {
        if (isLoading) {
            setCalendarStatus(calendarStatusStates.LOADING);
            return;
        }
        setCalendarStatus(calendarStatusStates.DONE);
    };

    const handleDeliveryAvailability = (isAvailable) => {
        if (isAvailable) {
            setShowProductUnavailable(false);
            return;
        }
        setShowProductUnavailable(true);
    };

    // package up data required for calendar
    const calendarData = {
        /**
         * Due to the calendar being a critical step in the checkout/
         * add to cart workflow, many props are passed to it to flow
         * through.
         *
         * The following props are the absolute bare minimum
         * to render the calendar onto the page.
         * */
        /** */ selectedItemId: selected.id,
        /** */ zipCode: zipcode,
        /** */ productDeliveryType: availability.productDeliveryType,
        /** */ setCalendarDataLoadingFlag,
        /** */ brandId,
        /** **** */

        /**
         * The following props are either tangential features
         * or passed through on click from other renderings.
         *
         * They are marked as required in GraphqlCalendarContainer.propTypes.
         */
        partNumber,
        productName: name,
        personalization: { modalOpen: false },
        selectedSku: selected,
        isFlowersSubscriptionFeatureEnabled: false,
        isFlowersTempV1SubscriptionFeatureEnabled: false,
        enableMinicart: false,
        handleMiniCartModalClick: noop,
        handleDeliveryAvailability,
        ageDetails,
        hasWine: selected.ageVerifyFlag,
    };

    /**
     * @description event handling for add to cart button
     * @note addToCartData only gets updated once date is set.
     */
    const handleAddToCartClick = () => {
        // tracking
        trackEvent({
            eventCategory: 'Collection Page',
            eventAction: 'Quickview Add to Cart',
            eventLabel: name,
        });

        /**
         * The calendar gets these variables from location.state:
         * - categoryId
         * - categoryName
         * - categoryPath
         *
         * Since we never actually change locations with quickview, these variables
         * are never set and should be reconciled here.
         */
        let categoryId = addToCartData.categoryId;
        let categoryName = addToCartData.categoryName;
        let categoryPath = addToCartData.categoryPath;
        if (!categoryId) {
            const locationState = linkPackage?.sendLinkTo?.state;
            // check linkPackage for an id
            categoryId = locationState?.categoryId;
            categoryName = locationState?.categoryName;
            categoryPath = locationState?.categoryPath;
        }
        if (zipcode.length < 5 && date === dateInitialState) {
            accountError([errorTypes.MISSING_ZIPCODE, errorTypes.MISSING_DELIVERY_DATE]);
            return;
        }
        if (zipcode.length < 5 && date !== dateInitialState) {
            accountError(errorTypes.MISSING_ZIPCODE);
            return;
        }
        if (!zipcode.length < 5 && date === dateInitialState) {
            accountError(errorTypes.MISSING_DELIVERY_DATE);
            return;
        }
        /**
         * Piggyback the calendar status states and set protal loading to show
         * when clicking add to cart. This redirects the user to a new page
         * so we dont need to worry about resetting state.
         */
        setCalendarStatus(calendarStatusStates.LOADING);
        if (priceRule) {
            addToCartData.reqObj.priceRules = priceRule;
        } else {
            // remove priceRules
            delete addToCartData.reqObj.priceRules;
        }
        console.log('addToCartData', addToCartData);
        actionAddToCart({
            item: [addToCartData.reqObj],
            history: addToCartData.history,
            categoryId,
            partNumber,
            categoryName,
            categoryPath,
            enableMinicart: addToCartData.enableMinicart,
            handleMiniCartModalClick: addToCartData.handleMiniCartModalClick,
            eventType: 'Product Page',
            passportBundle: addToCartData.isPassportBundleItem,
            movieSelected: addToCartData.movieSelected,
            handleClose: addToCartData.close,
            skipAddons: addToCartData.skipAddons || false,
            showWrapup: addToCartData.showWrapup || false,
            deliveryDate: addToCartData.date,
        });
    };

    const ViewFullProduct = () => {
        linkPackage.handleLinkOnClick();
        // tracking
        trackEvent({
            eventCategory: 'Collection Page',
            eventAction: 'Quickview View Full PDP',
            eventLabel: ReactHTMLParser(name),
        });
    };

    return (
        <>
            {calendarStatus === calendarStatusStates.LOADING && <GraphqlPortalLoader />}
            <Grid
                container
                direction="row"
                classes={{
                    root: classes.DialogGridContainer,
                }}
            >

                {/* Dialog left side */}
                <Grid
                    item
                    classes={{
                        root: classes.DialogGridLeftSide,
                    }}
                >
                    <Fade in timeout={700}>
                        <ResponsiveImage
                            path={`${image.path}${image.name}x.jpg?height=378&width=345&sharpen=a0.5,r1,t1&auto=webp`}
                            alt={name}
                            className={classes.QuickviewImage}
                        />
                    </Fade>
                </Grid>

                {/* Dialog right side */}
                <Grid
                    sm
                    item
                    classes={{
                        root: classes.DialogGridRightSide,
                    }}
                >

                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Typography
                                variant="h5"
                                classes={{
                                    h5: classes.QuickviewProductName,
                                }}
                            >
                                {ReactHTMLParser(name)}
                            </Typography>
                        </Grid>

                        <ProductSelectors
                            deliveryType={availability.productDeliveryType}
                            productSkus={productSkus}
                            selected={selected}
                            setSelected={setSelected}
                            productSelectorInitialState={productSelectorInitialState}

                            error={error}
                            resolveError={resolveError}
                            errorTypes={errorTypes}
                            dynamicPriceAttributes={dynamicPriceAttributes}
                        />

                        <hr className={classes.QuickviewLinebreak} />

                        {/* Dialog right side bottom */}
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Typography
                                variant="subtitle1"
                                classes={{
                                    subtitle1: classes.DeliveryDestinationText,
                                }}
                            >
                                Delivery Destination
                            </Typography>
                        </Grid>

                        <DeliverySelectors

                            // zipcode
                            zipcode={zipcode} // local
                            setZipcode={setZipcode} // local
                            isProductFilterZipcodeValid={isProductFilterZipcodeValid} // redux
                            clearValidatedZipcode={clearValidatedZipcode} // redux
                            validateProductFilterZipcode={validateProductFilterZipcode} // redux

                            // location
                            locationTypeOptions={locationTypeOptions} // data
                            locationType={locationType} // local
                            setLocationType={setLocationType} // local

                            // calendar
                            calendarData={calendarData}
                            productSelectorInitialState={productSelectorInitialState}
                            zipcodeInitialState={zipcodeInitialState}

                            // errors
                            error={error} // local
                            accountError={accountError} // local
                            resolveError={resolveError} // local
                            errorTypes={errorTypes} // local

                            // date
                            date={date} // local
                            setDate={setDate} // local

                            // ageVerifyFlag = true, before display calendar
                            ageDetails={ageDetails} // local
                            setAgeDetails={setAgeDetails} // local

                            // add to cart
                            setAddToCartData={setAddToCartData} // local

                            showProductUnavailable={showProductUnavailable}
                            setShowProductUnavailable={setShowProductUnavailable}
                            data={data}

                            categoryName={attributeCategoryName}
                            categoryPath={attributeCategoryPath}
                            categoryId={attributeCategoryId}
                        />

                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Button
                                classes={{
                                    root: classes.QuickviewAddToCart,
                                    disabled: classes.QuickviewAddToCartDisabled,
                                }}
                                onClick={handleAddToCartClick}
                            >
                                <Typography>Add to Cart</Typography>
                            </Button>
                        </Grid>

                        {addToCartError && <Grid><div className={classes.error}>{addToCartError}</div></Grid>}

                        <Grid>
                            <Link
                                id={linkPackage.id}
                                to={linkPackage.sendLinkTo}
                                onClick={ViewFullProduct}
                            >
                                View Full Product Details
                            </Link>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

QuickviewDialogContent.propTypes = {
    data: object.isRequired,

    // redux
    reduxLocationType: string.isRequired,
    isProductFilterZipcodeValid: bool, // not marked required to allow null initial state from redux
    clearValidatedZipcode: func.isRequired,
    validateProductFilterZipcode: func.isRequired,
    setBrandMovieSkus: func.isRequired,
    actionAddToCart: func.isRequired,
    userSubmittedProductFilterZipcode: string,

    linkPackage: shape({
        // information for to param in Link component
        sendLinkTo: shape({
            pathname: string.isRequired,
            search: string,
            state: object,
        }).isRequired,
        // tracking handler for onclick in link component
        handleLinkOnClick: func,
    }).isRequired,
    trackEvent: func.isRequired,
    isBot: bool,
    enterpriseId: string,
    currentBrandFeatureFlags: object,
    categoryId: string,
    categoryPath: string,
    categoryName: string,
    addToCartError: string.isRequired,
};

QuickviewDialogContent.defaultProps = {
    isProductFilterZipcodeValid: null,
    userSubmittedProductFilterZipcode: '',
    isBot: false,
    enterpriseId: '',
    currentBrandFeatureFlags: {},
    categoryId: '',
    categoryPath: '',
    categoryName: '',
};

const mapStateToProps = (state) => ({
    enterpriseId: getEnterpriseId(state),
    isBot: getIsBot(state),
    currentBrandFeatureFlags: getFeatureFlags(state),
    currentBrandPresentationFamily: getPresentationFamily(state),
    addToCartError: getAddToCartError(state),
});

export default connect(mapStateToProps)(QuickviewDialogContent);
