/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React, { useState, useEffect } from 'react';
import { string, shape, number } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { getSSRDeviceType } from '../../../../../../state/ducks/App/App-Selectors';

import Icon from '../../../../ContentTypeComponents/CustomIcons';

// strips "font-family:" and ";" from value
const parseFont = (font) => font?.match(/:\s(.*?),/, '')?.[1] || null;

const parsePosition = (position) => {
    const positionObject = {};
    const arr =  position?.split(',') || [];
    arr?.forEach((value) => {
        if (value) {
            const [propName, propValue] = value.split(':');
            positionObject[propName] = propValue.split("'").join('');
        }
    });
    return positionObject;
};
const maxFontSize = (styling) => (styling?.font_size ? `${styling.font_size}px` : '0.7rem');

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
    },
    dotWhack: {
        maxWidth: '95%',
        maxHeight: '94%',
        boxSizing: 'border-box',
        margin: 'clamp(0.3rem, 1vw, 0.8rem)',
        '& img': {
            height: '30%',
            width: 'min(30%, 8vw)',
        },
        [theme.breakpoints.down(600)]: {
            '& img': {
                height: '30%',
                width: '30% !important', // not sure why specificity is an issue here, so the !important stays :(
            },
        },
    },

    snipeContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: 'clamp(0.3rem, 1vw, 0.8rem)',
        padding: 'clamp(0.25rem, 1vw, 0.35rem) clamp(0.3rem, 1vw, 0.4rem)',
        height: 'fit-content',
        boxShadow: '2px 2px 3px rgba(47, 47, 47, 0.15)',
        background: '#ffffff',

        '& .customIcon svg': {
            marginRight: 'clamp(0.15rem, 1vw + .2rem, 0.4rem)',
        },
    },
    message: {
        display: 'flex',
        textAlign: 'center',
        fontStyle: 'normal',
        lineHeight: 'clamp(0.65rem, 0.6vw + .3rem, 0.9rem)',
        letterSpacing: '0.05em',
        textTransform: 'capitalize',

    },
    svg: {
        display: 'flex',
        alignItems: 'center',
        width: 'clamp(0.35rem, 0.9vw + .1rem, 0.85rem)',
        marginRight: 'clamp(0.15rem, 1vw, 0.2rem)',
        '& img': {
            width: '100%',
        },
    },
    mobileIconPDP: {
        '& svg': {
            width: '1rem',
            height: '1rem',
            marginRight: '.3rem',
        },
    },
    snipePDP: {
        '& $snipeContainer': {
            padding: 'clamp(0.25rem, 1vw, 0.35rem) 1rem',
        },
        '& $snipeContainerMobPDP': {
            padding: 'clamp(0.25rem, 1vw, 0.35rem) clamp(.7rem, 1vw, 1rem)',
        },
        '& $message': {
            lineHeight: '1rem',
            fontSize: '1rem',
        },
        '& $svg': {
            marginRight: '.7rem',
        },

    },
    snipeContainerMobPDP: {/* DO NOT DELETE!!! This declaration is here for the snipePDP class to manage the css heirarchy/specificty. Odd, I know. Don't go down this rabbit hole */},
    snipeSubCollection: {
        [theme.breakpoints.up(600)]: {
            alignItems: 'flex-end',
            justifyContent: 'center',
            whiteSpace: 'pre-line',
            '& $snipeContainer': {
                margin: '5px',
                padding: '4px 10px',
            },
        },
    },

}));

const SnipeRenderCMS = ({
    data, partNumber, productType, categoryId, imageContainerRef,
}) => {
    const isMobile = useSelector(getSSRDeviceType) === 'mobile';
    const isMobilePDP = isMobile && productType === 'product';
    const [isSmallContainer, setIsSmallContainer] = useState(false);
    const classes = useStyles();
    const getBaseCode = (sku) => {
        let baseCode = '';
        const skuSplit = sku?.split('-') || [];

        if (skuSplit.length) {
            baseCode = skuSplit[skuSplit.length - 1];
        }
        return baseCode;
    };

    const getWidth = () => {
        let widthVal;
        if (typeof window !== 'undefined') {
            widthVal = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        }
        return widthVal;
    };
    const [width, setWidth] = useState(getWidth());

    useEffect(() => {
        setIsSmallContainer(imageContainerRef?.current?.clientWidth < 220);
    }, [width]);

    // Need to track change in screen width to apply the various updated snipe display logic.
    useEffect(() => {
        let timeoutId = null;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setWidth(getWidth()), 1000);
        };
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', resizeListener);
            return () => window.removeEventListener('resize', resizeListener);
        }
        return null;
    }, []);

    const showIcon = (message, renderMobileIcon) => {
        if (!isSmallContainer && !isMobile) return true;
        if ((message?.length > 14 && isSmallContainer) || renderMobileIcon) return false;
        return true;
    };
    return data?.map((item) => {
        const { message = {}, styling = {}, targeting = {} } =   item || {};
        const icon = message.icon_svg || null;
        const renderMobileIcon = message.mobile_hide_icon || true;
        const position =  parsePosition(styling?.position);

        const iconDimensions = {
            width: 'clamp(0.35rem, 1vw + .2rem, 1.3rem)',
            height: 'clamp(0.35rem, 1vw + .2rem, 1.3rem)',
        };

        if (typeof targeting?.sku === 'string' && targeting.sku.length) {
            const currentProduct = getBaseCode(partNumber.toUpperCase()); // normalize
            const itemArr = targeting.sku.toUpperCase().split(/[\s*]+|(,\s*)/g);  // matches spaces, commas and some comma/space combos. Not necessary to deep-clean it as everything else will be ignored regardless.
            const getPartNumber = itemArr.find((id) => getBaseCode(id) === currentProduct);
            if (!getPartNumber) {
                return <></>;
            }
        } else  if (categoryId !== targeting?.collection) {
            return  <></>;
        } else {
            return <></>;
        }

        // If device is mobile & has mobMessage -or- there is limited space/width
        const appropriteMessage = message?.mobile_message && (isMobile || isSmallContainer) ? message?.mobile_message : message.message;

        // Remove ternarys from className invokation, because DRY - and its ugly.
        // Ternary is ultimately required because '&&' will pass 'false', which is no good.
        // eslint-disable-next-line no-confusing-arrow
        const iffy = (logic, selector) => logic ? selector : '';

        const snipeComponentClasses = () => (
                `
                ${classes.container}
                ${iffy(isSmallContainer, classes.snipeSubCollection)}
                ${iffy(productType === 'product', classes.snipePDP)}
                `
        );
        const snipeContainerClasses = () => (
            `
            ${classes.snipeContainer}
            ${iffy(isMobilePDP, classes.snipeContainerMobPDP)}
            `
        );

        const isDotWhack = () => item?.styling?.presentation_style === 'dot';
        const dotWhackClasses = () => (
            `
            ${classes.container}
            ${classes.dotWhack}
            `
        );

        if (isDotWhack()) {
            return (
                <div style={position ? { ...position } : {}}  className={dotWhackClasses()}>
                    <img src={item.message?.icon?.url} alt={item.message?.icon?.title} height="100" width="110" />
                </div>
            );
        }
        return (
            <div style={position ? { ...position } : {}} className={snipeComponentClasses()}>
                <div
                    style={{
                        background: styling?.color_background?.color || '#ffffff',
                    }}
                    className={snipeContainerClasses()}
                >
                    {/* First check if the new IconLibrary definitions are being used, and then check against "mobile_hide_icon" value */}
                    { (!isDotWhack && icon && showIcon(message, renderMobileIcon)) && <div className={iffy(isMobilePDP, classes.mobileIconPDP)}><Icon data={icon} dimensions={iconDimensions} /></div> }

                    {/* TEMP: if there is an old-style icon and NOT a new-style icon defined */}
                    {/* TODO: Remove this icon implementaiton when all snipes have shifted to IconLibrary */}
                    {(!icon && message.icon?.url && showIcon(message, renderMobileIcon)) && (
                        <div className={`${classes.svg} ${iffy(isMobilePDP, classes.mobileIconPDP)}`}>
                            <img src={message?.icon.url} alt={message?.icon.title} height="100" width="110" />
                        </div>
                    )}

                    <div
                        style={{
                            fontFamily: parseFont(styling?.font),
                            color: styling.color_message?.color || '#000000',
                            fontSize: `clamp(0.65rem, 0.9vw + .1rem, ${maxFontSize(styling)})`,
                        }}
                        className={classes.message}
                    >{appropriteMessage}
                    </div>
                </div>
            </div>
        );
    });
};
SnipeRenderCMS.propTypes = {
    data: shape({
        styling: shape({
            color_background: shape({
                color: string,
            }),
        }),
    }),
    categoryId: string,
    partNumber: string.isRequired,
    imageContainerRef: shape({
        current: shape({
            clientWidth: number,
        }),
    }),
    productType: string,
};
SnipeRenderCMS.defaultProps = {
    data: {},
    categoryId: '',
    imageContainerRef: {},
    productType: '',
};
export default (SnipeRenderCMS);
