/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { Component } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    Button,
    Grid,
} from '@material-ui/core';
import { bool, func, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    textFeildWrapper: {
        flex: '1 1 auto',
        flexDirection: 'row',
        justifyContent: 'center',
        display: 'inline-flex',
    },
    textFild: {
        width: '30%',
        '& .MuiFormHelperText-root': {
            fontSize: '10px',
        },
    },
    button: {
        width: '100%',
    },
    cancelBtn: {
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
    },
    submitBtn: {
        backgroundColor: '#61C278',
    },
    contentText: {
        fontSize: '14px',
        marginBottom: '0px',
    },
    errorText: {
        fontSize: '12px',
        color: theme.palette.error.main,
    },
    dialogTitle: {
        margin: '0 15px',
        padding: '16px 0 12px',
        borderBottom: `2px solid ${theme.palette.cms?.primary || theme.palette.colorPrimary}`,
        textAlign: 'center',
        '& h2': {
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '20px',
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
    },
});

export class ProductAgeVerificationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitBtnDay: false,
            submitBtnMonth: false,
            submitBtnYear: false,
            age: 0,
            birthDay: 0,
            birthMonth: 0,
            birthYear: 0,
            showError: false,
            dayErrorMessage: '',
            monthErrorMessage: '',
            yearErrorMessage: '',
        };
    }

    componentDidMount() {
        const { setCalendarDataLoadingFlag, isOpen } = this.props;
        if (isOpen) {
            setCalendarDataLoadingFlag(false);
        }
    }

    handleSubmit = () => {
        const { getAgeVerificationDetails } = this.props;
        const { birthDay, birthMonth, birthYear } = this.state;
        getAgeVerificationDetails(birthDay, birthMonth, birthYear);
    }

    getBirthDay = (e) => {
        this.setState({ birthDay: e.target.value }, () => {
            this.handleAgeVerification();
        });
    }

    getBirthMonth = (e) => {
        this.setState({ birthMonth: e.target.value }, () => {
            this.handleAgeVerification();
        });
    }

    getBirthYear = (e) => {
        this.setState({ birthYear: e.target.value }, () => {
            this.handleAgeVerification();
        });
    }

    handleAgeVerification = () => {
        const { birthDay, birthMonth, birthYear } = this.state;
        if (birthDay !== 0 && birthMonth !== 0 && birthYear !== 0) {
            const birthDate = new Date(birthYear, birthMonth - 1, birthDay);
            // const cur = new Date();
            const today = new Date();
            let age = today.getFullYear() - birthDate.getFullYear();
            const getMonth = today.getMonth() - birthDate.getMonth();
            if (getMonth < 0 || (getMonth === 0 && today.getDate() < birthDate.getDate())) {
                age -= 1;
            }
            // eslint-disable-next-line no-restricted-globals
            const isNotANumber = isNaN(age);
            if (age !== 0 && age > 0 && age < 21) {
                this.setState({ showError: true });
            } else {
                this.setState({ showError: false });
            }
            if (!isNotANumber) {
                this.setState({
                    age,
                });
            }
        }
    }

    validateFields = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        const numaricOnly = /^[0-9]+$/;
        const  current_year = new Date().getFullYear();
        let dayErrorMessage = '';
        let monthErrorMessage = '';
        let yearErrorMessage = '';
        let { submitBtnDay, submitBtnMonth, submitBtnYear } = this.state;
        if (id === 'day') {
            if (value ===  '' || value === '00') {
                dayErrorMessage = 'Date is required!';
                submitBtnDay = true;
            } else if (value.length !== 2) {
                dayErrorMessage = 'Date should be two digits!';
                submitBtnDay = true;
            } else if ((value > 31)) {
                dayErrorMessage = 'Date is invalid';
                submitBtnDay = true;
            } else if ((!numaricOnly.test(value))) {
                dayErrorMessage = 'Please enter numeric values only!';
                submitBtnDay = true;
            } else {
                dayErrorMessage = '';
                submitBtnDay = false;
            }
        }  if (id === 'month') {
            if (value ===  '' || value === '00') {
                monthErrorMessage = 'Month is required!';
                submitBtnMonth = true;
            } else if (value.length !== 2) {
                monthErrorMessage = 'Month should be two digits!';
                submitBtnMonth = true;
            } else if ((value > 12)) {
                monthErrorMessage = 'Month is invalid';
                submitBtnMonth = true;
            } else if ((!numaricOnly.test(value))) {
                monthErrorMessage = 'Please enter numeric values only!';
                submitBtnMonth = true;
            } else {
                monthErrorMessage = '';
                submitBtnMonth = false;
            }
        }  if (id === 'year') {
            if (value === '') {
                yearErrorMessage = 'Year is required!';
                submitBtnYear = true;
            } else if (value.length < 4) {
                yearErrorMessage = 'Year should be 4 digits!';
                submitBtnYear = true;
            } else if ((value < 1920) || (value > current_year)) {
                yearErrorMessage = 'Year is invalid!';
                submitBtnYear = true;
            } else if ((!numaricOnly.test(value))) {
                yearErrorMessage = 'Please enter numeric values only!';
                submitBtnYear = true;
            } else {
                monthErrorMessage = '';
                submitBtnYear = false;
            }
        }
        this.setState({
            dayErrorMessage, monthErrorMessage, yearErrorMessage, submitBtnDay, submitBtnMonth, submitBtnYear,
        });
    }

    render() {
        const { isOpen, handleAgeVerificationClose, classes } = this.props;
        const {
            age,
            showError,
            dayErrorMessage,
            monthErrorMessage,
            yearErrorMessage,
            submitBtnDay,
            submitBtnMonth,
            submitBtnYear,
        } = this.state;
        return (
            <div>
                <Dialog open={isOpen}>
                    <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>AGE VERIFICATION</DialogTitle>
                    <DialogContent>
                        <DialogContentText className={classes.contentText}>
                            Because this gift includes wine, state regulations require you to enter your date of birth as the sender.
                        </DialogContentText>
                        {
                            showError
                            && (
                                <DialogContentText className={classes.errorText}>
                                    You must be 21 years or older to order alcohol
                                </DialogContentText>
                            )
                        }
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid xs={3} sm={3} md={3} lg={3} item>
                                <span>Birth Date:</span>
                            </Grid>
                            <Grid className={classes.textFeildWrapper} xs={9} sm={9} md={9} lg={9} item>
                                <div className={classes.textFild}>
                                    <TextField
                                        margin="dense"
                                        id="day"
                                        label="DD"
                                        type="tel"
                                        onChange={this.getBirthDay}
                                        onBlur={this.validateFields}
                                        onKeyUp={this.validateFields}
                                        inputProps={{
                                            maxLength: 2,
                                            pattern: '[0-9]/g',
                                        }}
                                        error={dayErrorMessage !== ''}
                                        helperText={dayErrorMessage}
                                    />
                                </div>
                                <div className={classes.textFild}>
                                    <TextField
                                        margin="dense"
                                        id="month"
                                        label="MM"
                                        type="tel"
                                        onChange={this.getBirthMonth}
                                        onBlur={this.validateFields}
                                        onKeyUp={this.validateFields}
                                        inputProps={{
                                            maxLength: 2,
                                        }}
                                        error={monthErrorMessage !== ''}
                                        helperText={monthErrorMessage}
                                    />
                                </div>
                                <div className={classes.textFild}>
                                    <TextField
                                        margin="dense"
                                        id="year"
                                        label="YYYY"
                                        type="tel"
                                        onChange={this.getBirthYear}
                                        onKeyUp={this.validateFields}
                                        onBlur={this.validateFields}
                                        inputProps={{
                                            maxLength: 4,
                                        }}
                                        error={yearErrorMessage !== ''}
                                        helperText={yearErrorMessage}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAgeVerificationClose} color="primary" variant="outlined" className={`${classes.button} ${classes.cancelBtn}`}>
                            Cancel
                        </Button>
                        <Button onClick={this.handleSubmit} color="primary" variant="contained" disabled={age < 21 || age > 100 || submitBtnDay || submitBtnMonth || submitBtnYear} className={`${classes.button} ${classes.submitBtn}`}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ProductAgeVerificationModal.propTypes = {
    classes: object.isRequired,
    setCalendarDataLoadingFlag: func.isRequired,
    isOpen: bool.isRequired,
    /**
     * Inform the parent component this process was conceled.
     * This is basically onCancel.
     */
    handleAgeVerificationClose: func.isRequired,
    /**
     * Pass age details typed into the fields to the parent component.
     * This is basically onFinish.
     */
    getAgeVerificationDetails: func.isRequired,
};
export default withStyles(styles)(ProductAgeVerificationModal);
