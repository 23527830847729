/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { bool, shape, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown'; // because react-parse-html doesnt like collapsed tags.¯\_(ツ)_/¯

/**
 * @description Allows the inclusion of an SVG icon that has been defined in
 * both the CMS Icon Library content type and passed in via the Icon Library
 * extension.
 * @documentation This is the link for the Confluence documentation
 *  https://1800flowersinc.atlassian.net/wiki/spaces/RP/pages/2109407253/Custom+Icons+using+CMS+Icon+Library
 * @param {object} dimensions width: px/rem/min/max/clamp/minmax, height: : px/rem/min/max/clamp/minmax
 */

const useStyles = makeStyles(() => ({
    icon: ({ colorData, dimensions }) => ({
        display: 'flex',
        alignItems: 'center',
        '& > div': {
            display: 'flex',
        },
        '& svg': {
            width: dimensions.width,
            height: dimensions.height,
            fill: colorData.useCustom ? colorData.svgColor : 'currentColor',
        },
    }),
    'svg:not(:root)': {
        width: '100%',
    },
}));

const CustomIcons = ({
    data, dimensions,
}) => {
    const {
        useCustom = data.usecustom || false,
        svgData = data.svg_data || null,
        svgColor = data.custom_color?.color || null,
    } = data;
    if (!data || !svgData) return <></>;
    const colorData = { useCustom, svgColor };

    const classes = useStyles({ colorData, dimensions });

    return <span className={`${classes.icon} customIcon`} data-testId="customeIcon"><ReactMarkdown className={classes.container} source={svgData} escapeHtml={false} /></span>;
};

CustomIcons.defaultProps = {
    dimensions: {
        width: '1rem',
        height: '1rem',
    },
};
CustomIcons.propTypes = {
    data: shape({
        svg_data: string,
        custom_color: shape({
            color: string,
        }),
        useCustom: bool,
    }).isRequired,
    dimensions: shape({
        width: string,
        height: string,
    }),
};

export default CustomIcons;
