/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { string, bool } from 'prop-types';
import { useSelector } from 'react-redux';
import { getFeatureFlag  } from '../../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import LinkOrATag from '../../../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const useStyles = makeStyles((theme) => ({
    addToCartCTA: {
        display: 'flex',
        flexBasis: '15%',
        width: '100%',
        marginTop: '10px',
        cursor: 'pointer',
        '& a': {
            alignSelf: 'flex-end',
            display: 'block',
            width: '100%',
            padding: '5px 0px',
            border: `1px solid ${theme.palette.colorPrimary || '#65388b'}`,
            borderRadius: '3px',
            textAlign: 'center',
            textDecoration: 'none',
            fontSize: '1em',
            color: theme.palette.colorPrimary || '#65388b',
        },
    },
}));

const OOSAddToCart = ({
    url, productNotAvaliable,
}) => {
    const isCTAOOSPageEnabled = useSelector((state) => getFeatureFlag('is-cta-oos-page-enabled')(state));
    const isOOSCTAEnabled = isCTAOOSPageEnabled && productNotAvaliable;

    if (!isOOSCTAEnabled) {
        return null;
    }

    const classes = useStyles();

    return (
        <div className={classes.addToCartCTA}>
            <LinkOrATag
                href={url}
            >
                Add to Cart
            </LinkOrATag>
        </div>
    );
};

OOSAddToCart.propTypes = {
    url: string.isRequired,
    productNotAvaliable: bool,
};

OOSAddToCart.defaultProps = {
    productNotAvaliable: false,
};

export default OOSAddToCart;
