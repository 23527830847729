/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { parseISO, isValid, differenceInYears } from 'date-fns';
import Media from 'react-media';
import {
    object, func, string, bool, shape, number, array,
} from 'prop-types';
import * as app from '../../../../../../state/ducks/App';
import {
    validateProductFilterZipcode,
    clearValidatedZipcode,
    resetProductFilterZipcodeValidity,
    setUserSubmittedProductFilterZipcode,
    clearUserSubmittedProductFilterZipcode,
    setProductFilterZipcodeValidity,
    setValidatedZipcode,
    verifyWine,
    resetWineData,
    resetAgeVerifyFlag,
    setWineAgeVerify,
} from '../../../../../../state/ducks/App/App-Actions';
import {
    getValidatedZipcode,
    getUserSubmittedProductFilterZipcode,
    getIsProductFilterZipcodeValid,
    getWineData,
    getSelectedInlineAddons,
} from '../../../../../../state/ducks/App/App-Selectors';
import ProductVerification from './ProductVerification';
import ConnectedAddonVerification from './AddonVerification';
import { addToCartDataType } from '../common/types';
import { getSkuWithoutBrand } from '../../../../../helpers/common/helper';
import { getDisplayAdditionalCompliance, getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const styles = (theme) => ({

    ageInput: {
        width: '100%',
        display: 'flex',  // IE 11
        padding: '0px 0px 20px 15px',
        position: 'relative',
        '& > div': {
            width: '100%', // IE 11
        },
        '@media (max-width: 767px)': {
            padding: '4px 0px 8px 15px',
            position: 'relative',
        },
    },

    ageInputInvalidIcon: {
        '&::after': {
            position: 'absolute',
            zIndex: '99',
            margin: '-38px 0px 0px 215px',
            backgroundColor: theme.palette.cms?.errorZipVerifyPDP || '#79000f',
            color: theme.palette.white,
            textAlign: 'center',
            content: '\'\\2715\'',
            fontSize: '14px',
            padding: '3px',
            fontWeight: 'bold',
            lineHeight: '21px',
            left: '7px',
            width: '25px',
            height: '25px',
            borderRadius: '50%',
            animation: 'fadeZoomIn 0.3s ease-in',
            display: 'inline-block', // IE 11
            '@media (max-width: 767px)': {
                margin: '-60px 0px 0px 215px',
            },
        },
    },

    ageInputInvalid: {
        '&::before': {
            // position: 'absolute',
            zIndex: '99',
            color: theme.palette.cms?.errorZipVerifyPDP || '#79000f',
            lineHeight: '25px',
            fontSize: '12px',
            border: 'none',
            fontWeight: '400',
            content: '"You must be 21 or older to purchase wine!"',
            height: '22px',
            transition: 'opacity 0.3s ease-in',
            opacity: 1,
            [theme.breakpoints.down(480)]: {
                top: '-5px',
                left: '12px',
            },
        },
    },

    ageInputTextValid: {
        '&::before': {
            position: 'absolute',
            zIndex: '99',
            color: theme.palette.colorValid || '#24b200',
            lineHeight: '25px',
            fontSize: '14px',
            fontWeight: '400',
            content: '"Age Confirmed"',
            width: '150px',
            margin: '0px 0px 0px 12px',
            border: 'none',
            transition: 'opacity 0.3s ease-in',
            opacity: 1,
        },
    },
    ageInputValid: {
        '&::after': {
            position: 'absolute',
            zIndex: '99',
            backgroundColor: theme.palette.colorValid || '#24b200',
            color: theme.palette.white,
            textAlign: 'center',
            content: '"✓"',
            margin: '-38px 0px 0px 215px',
            fontSize: '16px',
            padding: '7px',
            fontWeight: 'bold',
            lineHeight: '19px',
            width: '25px',
            height: '25px',
            borderRadius: '50%',
            animation: 'fadeZoomIn 0.3s ease-in',
            display: 'inline-block', // IE 11
            '@media (max-width: 767px)': {
                margin: '-50px 0px 0px 205px',
            },
        },
    },

    zipCodeInput: {
        width: '100%',
        display: 'block',  // IE 11
        '& > div': {
            width: '100%', // IE 11
        },
    },
    zipCodeInputValid: {
        '&::after': {
            position: 'absolute',
            zIndex: '99',
            // margin: '25px 0px 0px 50px',
            backgroundColor: theme.palette.colorValid || '#24b200',
            color: theme.palette.white,
            textAlign: 'center',
            content: '"✓"',
            margin: '-60px 0px 0px 95px',
            fontSize: '16px',
            padding: '5px',
            fontWeight: 'bold',
            lineHeight: '17px',
            width: '25px',
            height: '25px',
            borderRadius: '50%',
            animation: 'fadeZoomIn 0.3s ease-in',
            display: 'inline-block', // IE 11
        },
    },

    zipInputTextValid: {
        position: 'relative',
        '&::before': {
            position: 'absolute',
            zIndex: '99',
            color: theme.palette.colorValid || '#24b200',
            lineHeight: '25px',
            fontSize: '14px',
            fontWeight: '400',
            content: '"ZIP code confirmed eligible "',
            margin: '3px 0px 0px 0px',
            border: 'none',
            transition: 'opacity 0.3s ease-in',
            opacity: 1,
            width: '200px',
        },
    },

    zipCodeInputInvalidIcon: {
        '&::after': {
            position: 'absolute',
            zIndex: '99',
            backgroundColor: theme.palette.cms?.errorZipVerifyPDP || '#79000f',
            color: theme.palette.white,
            textAlign: 'center',
            content: '\'\\2715\'',
            fontSize: '14px',
            padding: '5px',
            fontWeight: 'bold',
            margin: '-32px 0px 0px 96px',
            lineHeight: '16px',
            width: '25px',
            height: '25px',
            borderRadius: '50%',
            animation: 'fadeZoomIn 0.3s ease-in',
            display: 'inline-block', // IE 11
        },
    },

    zipCodeInputInvalid: {
        position: 'relative',
        '&::before': {
            position: 'absolute',
            zIndex: '99',
            color: theme.palette.cms?.errorZipVerifyPDP || '#79000f',
            lineHeight: '25px',
            fontSize: '14px',
            fontWeight: '400',
            content: '"Not eligible for Wine shipment"',
            bottom: '-28px',
            left: 0,
            paddingLeft: '0px',
            width: '220px',
            height: '25px',
            transition: 'opacity 0.3s ease-in',
            opacity: 1,
        },
    },

});

const isCanadaZip = (zipcode) => new RegExp(/^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[- ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}/).test(zipcode);
const brandSelectors = app.ducks.brand.selectors;
let updatedDayErrorMessage = '';
let updatedMonthErrorMessage = '';
let updatedYearErrorMessage = '';
class CommonProductAgeZipVerify extends Component {
    constructor(props) {
        super(props);
        const { userSubmittedZip } = this.props;
        this.state = {
            zipField: userSubmittedZip || '',
            zipValid: null,
            inputDayValidated: false,
            inputMonthValidated: false,
            inputYearValidated: false,
            birthDay: '',
            birthMonth: '',
            birthYear: '',
            dayErrorMessage: '',
            monthErrorMessage: '',
            yearErrorMessage: '',
        };
        this.zipFieldRef = React.createRef();
    }

    componentDidMount() {
        const {
            selectedSku,
            zipValid,
            resetFilterZipcodeValidity,
            resetWineDataAll,
        } = this.props;

        // age verify flag is not a boooollleaaannnnnn
        // we dont render unless we get past this guard statement
        if (!selectedSku?.ageVerifyFlag || selectedSku?.ageVerifyFlag === 'false') {
            return false;
        }

        if (zipValid === false) {
            resetFilterZipcodeValidity();
        }
        resetWineDataAll();
        return true;
    }

    getBirthDay = (e) => {
        const value = e.target.value;
        const newObj =  {
            target: {
                id: e.target.id,
                value: e.target.value,
            },
        };
        const numericOnly = /^[0-9]+$/;
        if (value && (Number.isNaN(Number(value)) || Number(value) > 31 || !numericOnly.test(value))) {
            this.validateFields(newObj);
            return;
        }
        this.setState({ birthDay: value }, () => {
            this.validateFields(newObj, this.zipFieldRef);
        });
    }

    getBirthMonth = (e) => {
        const value = e.target.value;
        const newObj =  {
            target: {
                id: e.target.id,
                value: e.target.value,
            },
        };
        const numericOnly = /^[0-9]+$/;

        if (value && (Number.isNaN(Number(value)) || Number(value) > 12 || !numericOnly.test(value))) {
            this.validateFields(newObj);
            return;
        }

        this.setState({ birthMonth: value }, () => {
            this.validateFields(newObj);
        });
    }

    getBirthYear = (e) => {
        const value = e.target.value;
        const newObj =  {
            target: {
                id: e.target.id,
                value: e.target.value,
            },
        };
        const numericOnly = /^[0-9]+$/;
        if (value && (Number.isNaN(Number(value)) || !numericOnly.test(value))) {
            this.validateFields(newObj);
            return;
        }
        this.setState({ birthYear: value }, () => {
            this.validateFields(newObj, this.zipFieldRef);
        });
    }

    zeroFill = (value) => `${value}`.replace(/^(\d)$/, '0$1') // the regex adds a preceding 0 for a single digit nnumber

    /*
    * @description verifying the age based on day/month/year and default min age value is 21
    */
    handleAgeVerification = () => {
        const { classes, wineAgeVerify } = this.props;
        const {
            birthDay, birthMonth, birthYear, inputYearValidated, inputDayValidated, inputMonthValidated,
        } = this.state;
        const day = this.zeroFill(birthDay);
        const month = this.zeroFill(birthMonth);
        const { productSkus } = this.props;
        if (!birthDay || !birthMonth || !birthYear) {
            wineAgeVerify(false);
            return {
                class: `${classes.ageInput}`,
            };
        }
        const birthDate = new Date().setFullYear(birthYear, birthMonth - 1, birthDay);
        const today = new Date();
        const presentAge = differenceInYears(today, birthDate);
        const isMinAge = productSkus?.[0]?.minAge > 0 ? productSkus?.[0]?.minAge : '21';
        const parseDate = parseISO(`${birthYear}-${month}-${day}`);
        const validDate = isValid(parseDate);
        if (presentAge !== '' && !validDate) {
            wineAgeVerify(false);
            return {
                class: `${classes.ageInput} ${classes.ageInputInvalidIcon} error error-invalid-date`,
                flag: false,
            };
        }
        if (presentAge < Number(isMinAge) && (!inputDayValidated || !inputMonthValidated) && !inputYearValidated) {
            wineAgeVerify(false);
            return {
                class: `${classes.ageInput} ${classes.ageInputInvalid} ${classes.ageInputInvalidIcon} error`,
                flag: false,
            };
        }
        if (presentAge >= Number(isMinAge) && presentAge <= 120 && (!inputDayValidated || !inputMonthValidated || !inputYearValidated)) {
            wineAgeVerify(true);
            return {
                class: `${classes.ageInput} ${classes.ageInputValid} ${classes.ageInputTextValid} success`,
                flag: true,
            };
        }
        if (presentAge > 120) {
            wineAgeVerify(false);
            return {
                class: `${classes.ageInput} ${classes.ageInputInvalidIcon} error error-invalid-date`,
                flag: false,
            };
        }
        return {
            class: `${classes.ageInput} `,
        };
    };

    validateFields = (e, zipFieldRef = null) => {
        const id =  e.target.id;
        const value =  e.target.value;
        const numericOnly = /^[0-9]+$/;
        const current_year = new Date().getFullYear();
        let { inputDayValidated, inputMonthValidated, inputYearValidated } = this.state;
        const {
            zipField, birthDay, birthMonth, birthYear,
        } = this.state;
        const birthDate = new Date(birthYear, birthMonth - 1, birthDay);
        const today = new Date();
        const presentAge = differenceInYears(today, birthDate);

        if (id === 'day') {
            if (value === '') {
                updatedDayErrorMessage = 'Day required';
                inputDayValidated = true;
            } else if (!numericOnly.test(value) || value > 31 || value < 1) {
                updatedDayErrorMessage = 'Day not valid';
                inputDayValidated = true;
            } else {
                updatedDayErrorMessage = '';
                inputDayValidated = false;
            }
        } if (id === 'month') {
            if (value === '') {
                updatedMonthErrorMessage = 'Month required';
                inputMonthValidated = true;
            } else if (!numericOnly.test(value) || value > 12 || value < 1) {
                updatedMonthErrorMessage = 'Month not valid';
                inputMonthValidated = true;
            } else {
                updatedMonthErrorMessage = '';
                inputMonthValidated = false;
            }
        } if (id === 'year') {
            if (value === '') {
                updatedYearErrorMessage = 'Year required';
                inputYearValidated = true;
            } else if (numericOnly.test(value) && value < 100) {
                updatedYearErrorMessage = '4-digit year required';
                inputYearValidated = true;
            } else if (!numericOnly.test(value) || value > current_year || presentAge > 120) {
                updatedYearErrorMessage = 'Year not valid';
                inputYearValidated = true;
            } else {
                updatedYearErrorMessage = '';
                inputYearValidated = false;
            }
        }
        this.setState({
            dayErrorMessage: updatedDayErrorMessage,
            monthErrorMessage: updatedMonthErrorMessage,
            yearErrorMessage: updatedYearErrorMessage,
            inputDayValidated,
            inputMonthValidated,
            inputYearValidated,
        }, () => {
            if (zipFieldRef?.current && updatedMonthErrorMessage === '' && updatedYearErrorMessage === '' && updatedDayErrorMessage === '') {
                if (id === 'year' && value.length === 4) {
                    zipFieldRef.current.focus();
                }
                /**
                 * If user has already zipcode it will call to API and make ATC button enabled
                 */
                if (zipField) {
                    this.handleZipChange({ target: { value: zipField } });
                }
            }
        });
    };

    convertZipCode = (zipcode, preState) => {
        // handle zipcode conversion for Canada as originally made by Edison.  TODO - test/fix this
        if (preState.length < zipcode.length
            && zipcode.length >= 6
            && isCanadaZip(zipcode)) {
            const zipSplit = zipcode.replace(/\s/gi, '').toUpperCase().split('');
            zipSplit.splice(3, 0, ' ');
            return zipSplit.join('');
        }
        return zipcode;
    }

    handleZipChange = (e) => {
        let zipCode = e.target.value;
        const { zipField } = this.state;
        const numericOnly = /^[0-9]+$/;
        if (zipCode && (Number.isNaN(Number(zipCode)) || !numericOnly.test(e.target.value))) {
            return;
        }
        if (isCanadaZip(zipCode)) {
            zipCode = this.convertZipCode(zipCode, zipField);
        }
        this.setState(
            {
                zipField: zipCode,
            }, () => {
                this.validateZip();
            },
        );
    };

    /*
    * @description get the availability from API response
    * @param {object} data
    */
    getAvailability = (data) => {
        let availability = false;
        if (!data?.wineAvailability) {
            return false;
        }
        const keys = Object.keys(data.wineAvailability);
        keys.forEach((key) => {
            if (data.wineAvailability[key]?.isProductAvailable) {
                availability = true;
            }
        });
        return availability;
    }

     /*
    * @description get the zipCode from API response
    * @param {object} data
    */
     getZipCode = (data) => {
         let zipCode = true;
         if (!data?.wineAvailability) {
             return false;
         }
         const keys = Object.keys(data.wineAvailability);
         keys.forEach((key) => {
             if (data.wineAvailability[key]?.zipCode?.length < 5) {
                 zipCode = false;
             }
         });
         return zipCode;
     }

    /*
    * @description This is showing the error/success message after the fields
    */
    displayZipValidCSSCheck = () => {
        const { zipField } = this.state;
        const {
            classes, wineValidation, resetWineDataAll, isAddonAvailable, handleMobileAgeVerification, mobileAgeVerificationOpen,
            zipValid,
        } = this.props;
        if (wineValidation) {
            if ((zipField.length < 5 && this.getZipCode(wineValidation))) {
                resetWineDataAll();
            }
            if (!isAddonAvailable && zipField.length < 5 &&  this.getZipCode(wineValidation)) {
                resetWineDataAll();
            }
            if (this.getAvailability(wineValidation) && zipValid && zipField.length >= 5) {
                if (mobileAgeVerificationOpen) {
                    handleMobileAgeVerification(false);
                }
                return {
                    classes: `${classes.zipCodeInput} ${classes.zipCodeInputValid} ${classes.zipInputTextValid} zip-code-success`,
                    flag: true,
                };
            }
            return {
                classes: `${classes.zipCodeInput} ${classes.zipCodeInputInvalid} ${classes.zipCodeInputInvalidIcon} zip-code-error`,
                flag: false,
            };
        }
        return {
            classes: `${classes.zipCodeInput}`,
        };
    };

    validateZip = () => {
        // this function determines when to validate a zipcode that the user enters
        const { zipField } = this.state;
        if (zipField) {
            const {
                valZip,
                clearZip,
                zipValid,
                resetZipValidity,
                validatedZip,
                clearFilterZip,
                userSubmittedZip,
            } = this.props;
            if (zipField.length >= 5) {
                valZip(zipField);    // validate zip code.  set redux isProductFilterZipcodeValid true/false and, if valid, validatedZipcode = zipField
                this.submitWineForm();
            } else {
                // assume zip not valid.  clear redux values where necessary.
                if (validatedZip) clearZip();    // if set, set redux validatedZipcode to ''
                if (userSubmittedZip) clearFilterZip();  // if set, set redux userSubmittedProductFilterZipcode to ''
            }
            if (zipField.length !== 5 && zipValid) {  // if zipField length wrong and isProductFilterZipcodeValid is true,
                resetZipValidity();  // set redux isProductFilterZipcodeValid to false
            }
        }
    };

    getCompiledComponent = (items) => {
        const { selectedAddons } = this.props;
        const components = [];
        if (items.length > 0) {
            items.forEach((item) => {
                if (item?.productCode) {
                    components.push(
                        {
                            componentType: item.componentType,
                            componentInfo: {
                                productSku: getSkuWithoutBrand(item.productCode),
                                brandCode: item.brandCode,
                                quantity: item.quantity,
                            },
                        },
                    );
                }
            });
        }
        if (selectedAddons?.length > 0) {
            selectedAddons?.forEach((item) => {
                components.push(
                    {
                        componentType: 'ADDON',
                        componentInfo: {
                            productSku: getSkuWithoutBrand(item?.partNumber),
                            brandCode: item?.brandId,
                            quantity: item?.qty,
                        },
                    },
                );
            });
        }
        return components;
    }

    /*
    * @description Submitting the wine form to API.
    */

    submitWineForm = () => {
        const {
            defaultSku, callVerifyWine, addItemAddon, wineValidation, addToCartData, handleMobileAgeVerification, quantity, components, ffIsWineVerificationNewApi, pdpInlineAddon,
        } = this.props;
        const { selectedSku, brand } = this.props;
        const brandCode =  selectedSku?.brandId || brand?.id;
        const minAge = selectedSku?.minAge > 0 ? selectedSku?.minAge : '21';
        const skuPartNumber = defaultSku || selectedSku?.id;
        const {
            zipField,
            birthYear,
        } = this.state;
        let {
            birthMonth,
            birthDay,
        } = this.state;
        birthDay = this.zeroFill(birthDay);
        birthMonth = this.zeroFill(birthMonth);
        if (skuPartNumber && zipField && birthMonth && birthYear && birthDay) {
            const wineData = {
                birthDay,
                birthMonth,
                birthYear,
                brandCode: selectedSku?.brandId || brandCode,
                minAge,
                skuPartNumber,
                zipCodes: [zipField],
            };

            const payload = {
                wineVerify: {
                    birthDay,
                    birthMonth,
                    birthYear,
                    minAge,
                },
                items: [
                    {
                        itemInfo: {
                            productSku: getSkuWithoutBrand(skuPartNumber),
                            brandCode: selectedSku?.brandId || brandCode,
                            quantity,
                        },
                        locationInfo: {
                            zipCode: zipField,
                        },
                        components: this.getCompiledComponent(components),
                    },
                ],
            };

            if (ffIsWineVerificationNewApi) {
                // Verify wine age-verification and availability.  Add to cart if both good.
                callVerifyWine(payload, pdpInlineAddon ? {} : addToCartData, handleMobileAgeVerification);
            } else {
                // Verify wine age-verification and availability.  Add to cart if both good.
                callVerifyWine(wineData, pdpInlineAddon ? {} : addToCartData, handleMobileAgeVerification);
            }
            const wineValidationData = {
                ...wineValidation,
                birthDay,
                birthMonth,
            };
            if (this.getAvailability(wineValidationData)) {
                addItemAddon(selectedSku, 'add');
            }
        }
    }

    /*
    * @description designing the form data for API request
    */
    getWineData = () => {
        const { selectedSku, brand } = this.props;
        const {
            zipField,
            birthDay,
            birthMonth,
            birthYear,
            inputDayValidated,
            inputMonthValidated,
            inputYearValidated,
            zipValid,
        } = this.state;
        const skuPartNumber = selectedSku.id;
        const minAge = selectedSku?.minAge;
        const brandCode =  selectedSku?.brandId || brand?.id;
        const zipCodes = zipField;

        if (skuPartNumber && zipCodes && birthDay && birthMonth && birthYear && zipValid === true && (inputDayValidated || !inputMonthValidated || !inputYearValidated)) {
            const wineData = {
                birthDay: this.zeroFill(birthDay),
                birthMonth: this.zeroFill(birthMonth),
                birthYear,
                brandCode,
                minAge,
                skuPartNumber,
                zipCodes,
            };
            return wineData;
        }
        return null;
    };

    render() {
        const {
            classes,
            oncloseDrawer,
            isAddonAvailable,
            addItemAddon,
            selectedSku,
            mobileAgeVerificationOpen,
            handleMobileAgeVerification,
            presentationFamily,
            hasAddons,
            displayAdditionalCompliance,
            forceModal,
            featureFlags,
            pdpInlineAddon,
            open,
            setOpen,
            handleAddToCart,
            addToCartData,
            productBrand,
            addonWindow,
            brandWineModelOnAction,
        } = this.props;
        const {
            zipField,
            dayErrorMessage,
            monthErrorMessage,
            yearErrorMessage,
            birthDay,
            birthMonth,
            birthYear,
        } = this.state;

        // age verify flag is not a boooollleaaannnnnn
        if (!selectedSku?.ageVerifyFlag || selectedSku?.ageVerifyFlag === 'false') {
            return null;
        }
        const wineModelOnAction = featureFlags['is-inline-wine-verification-model-on-atc-enabled'] || brandWineModelOnAction || false;

        if (presentationFamily === 'flower' || forceModal) {
            return (
                <div data-testid="age-verification-modal">
                    <ConnectedAddonVerification
                        isModal
                        hasAddons={hasAddons}
                        classes={classes}
                        zipField={zipField}
                        dayErrorMessage={dayErrorMessage}
                        monthErrorMessage={monthErrorMessage}
                        yearErrorMessage={yearErrorMessage}
                        oncloseDrawer={oncloseDrawer}
                        zipFieldRef={this.zipFieldRef}
                        getBirthDay={this.getBirthDay}
                        getBirthMonth={this.getBirthMonth}
                        getBirthYear={this.getBirthYear}
                        handleAgeVerification={this.handleAgeVerification}
                        validateFields={this.validateFields}
                        handleZipChange={this.handleZipChange}
                        getAvailability={this.getAvailability}
                        displayZipValidCSSCheck={this.displayZipValidCSSCheck}
                        validateZip={this.validateZip}
                        submitWineForm={this.submitWineForm}
                        getWineData={this.getWineData}
                        addItemAddon={addItemAddon}
                        selectedItem={selectedSku}
                        birthDay={birthDay}
                        birthMonth={birthMonth}
                        birthYear={birthYear}
                        mobileAgeVerificationOpen={mobileAgeVerificationOpen}
                        handleMobileAgeVerification={handleMobileAgeVerification}
                        displayAdditionalCompliance={displayAdditionalCompliance}
                    />
                </div>
            );
        }

        return (
            <>
                {((isAddonAvailable || (pdpInlineAddon && wineModelOnAction)) || addonWindow) ? (
                    <div data-testid="desktop-age-verification-addon-drawer">
                        <ConnectedAddonVerification
                            classes={classes}
                            zipField={zipField}
                            dayErrorMessage={dayErrorMessage}
                            monthErrorMessage={monthErrorMessage}
                            yearErrorMessage={yearErrorMessage}
                            oncloseDrawer={oncloseDrawer}
                            zipFieldRef={this.zipFieldRef}
                            getBirthDay={this.getBirthDay}
                            getBirthMonth={this.getBirthMonth}
                            getBirthYear={this.getBirthYear}
                            handleAgeVerification={this.handleAgeVerification}
                            validateFields={this.validateFields}
                            handleZipChange={this.handleZipChange}
                            getAvailability={this.getAvailability}
                            displayZipValidCSSCheck={this.displayZipValidCSSCheck}
                            validateZip={this.validateZip}
                            submitWineForm={this.submitWineForm}
                            getWineData={this.getWineData}
                            addItemAddon={addItemAddon}
                            selectedItem={selectedSku}
                            birthDay={birthDay}
                            birthMonth={birthMonth}
                            birthYear={birthYear}
                            pdpInlineAddon={pdpInlineAddon}
                            isModal={!!pdpInlineAddon}
                            open={open}
                            setOpen={setOpen}
                            handleAddToCart={handleAddToCart}
                            addToCartData={addToCartData}
                            productBrand={productBrand}
                        />
                    </div>
                ) : (!wineModelOnAction &&  !pdpInlineAddon) && (
                    <Media queries={{ small: '(max-width: 599px)' }}>
                        {(matches) => (matches.small ? (
                            <div data-testid="mobile-age-verification-modal">
                                <ConnectedAddonVerification
                                    isModal
                                    classes={classes}
                                    zipField={zipField}
                                    dayErrorMessage={dayErrorMessage}
                                    monthErrorMessage={monthErrorMessage}
                                    yearErrorMessage={yearErrorMessage}
                                    oncloseDrawer={oncloseDrawer}
                                    zipFieldRef={this.zipFieldRef}
                                    getBirthDay={this.getBirthDay}
                                    getBirthMonth={this.getBirthMonth}
                                    getBirthYear={this.getBirthYear}
                                    handleAgeVerification={this.handleAgeVerification}
                                    validateFields={this.validateFields}
                                    handleZipChange={this.handleZipChange}
                                    getAvailability={this.getAvailability}
                                    displayZipValidCSSCheck={this.displayZipValidCSSCheck}
                                    validateZip={this.validateZip}
                                    submitWineForm={this.submitWineForm}
                                    getWineData={this.getWineData}
                                    addItemAddon={addItemAddon}
                                    selectedItem={selectedSku}
                                    birthDay={birthDay}
                                    birthMonth={birthMonth}
                                    birthYear={birthYear}
                                    mobileAgeVerificationOpen={mobileAgeVerificationOpen}
                                    handleMobileAgeVerification={handleMobileAgeVerification}
                                    displayAdditionalCompliance={displayAdditionalCompliance}
                                />
                            </div>
                        ) : (
                            <div data-testid="desktop-age-verification-card">
                                <ProductVerification
                                    classes={classes}
                                    zipField={zipField}
                                    dayErrorMessage={dayErrorMessage}
                                    monthErrorMessage={monthErrorMessage}
                                    yearErrorMessage={yearErrorMessage}
                                    zipFieldRef={this.zipFieldRef}
                                    getBirthDay={this.getBirthDay}
                                    getBirthMonth={this.getBirthMonth}
                                    getBirthYear={this.getBirthYear}
                                    handleAgeVerification={this.handleAgeVerification}
                                    validateFields={this.validateFields}
                                    handleZipChange={this.handleZipChange}
                                    getAvailability={this.getAvailability}
                                    displayZipValidCSSCheck={this.displayZipValidCSSCheck}
                                    validateZip={this.validateZip}
                                    submitWineForm={this.submitWineForm}
                                    getWineData={this.getWineData}
                                    birthDay={birthDay}
                                    birthMonth={birthMonth}
                                    birthYear={birthYear}
                                    featureFlags={featureFlags}
                                />
                            </div>
                        ))}
                    </Media>

                )}
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    validatedZip: getValidatedZipcode(state),
    zipValid: getIsProductFilterZipcodeValid(state),
    userSubmittedZip: getUserSubmittedProductFilterZipcode(state),
    brand: brandSelectors.getBrand(state),
    wineValidation: getWineData(state),
    displayAdditionalCompliance: getDisplayAdditionalCompliance(state),
    ffIsWineVerificationNewApi: getFeatureFlag('is-wine-verification-new-api-enabled')(state),
    brandWineModelOnAction: getFeatureFlag('is-inline-wine-verification-model-on-atc-enabled')(state),
    selectedAddons: getSelectedInlineAddons(state),
});

const mapDispatchToProps = (dispatch) => ({
    resetZipValidity: bindActionCreators(
        resetProductFilterZipcodeValidity,
        dispatch,
    ),
    setFilterZip: bindActionCreators(
        setUserSubmittedProductFilterZipcode,
        dispatch,
    ),
    setValZip: bindActionCreators(setValidatedZipcode, dispatch),
    callVerifyWine: bindActionCreators(verifyWine, dispatch),
    clearFilterZip: bindActionCreators(clearUserSubmittedProductFilterZipcode, dispatch),
    clearZip: bindActionCreators(clearValidatedZipcode, dispatch),
    setZipcodeValidity: bindActionCreators(setProductFilterZipcodeValidity, dispatch),
    valZip: bindActionCreators(validateProductFilterZipcode, dispatch),
    resetFilterZipcodeValidity: bindActionCreators(resetProductFilterZipcodeValidity, dispatch),
    resetWineDataAll: bindActionCreators(resetWineData, dispatch),
    resetContinueVal: bindActionCreators(resetAgeVerifyFlag, dispatch),
    wineAgeVerify: bindActionCreators(setWineAgeVerify, dispatch),

});
CommonProductAgeZipVerify.propTypes = {
    classes: object.isRequired,
    presentationFamily: string.isRequired,
    brand: object.isRequired,
    forceModal: bool,
    resetZipValidity: func.isRequired,
    oncloseDrawer: func.isRequired,
    validatedZip: string.isRequired,
    zipValid: bool,
    valZip: func.isRequired,
    productSkus: shape({
        minAge: string.isRequired,
    }).isRequired,
    clearZip: func.isRequired,
    userSubmittedZip: string.isRequired,
    clearFilterZip: func.isRequired,
    resetFilterZipcodeValidity: func.isRequired,
    callVerifyWine: func.isRequired,
    addItemAddon: func,
    selectedSku: shape({
        id: string.isRequired,
    }).isRequired,
    resetWineDataAll: func.isRequired,
    wineValidation: shape({
        wineAvailability: object, // object type array
        wineVerify: shape({
            birthDay: 'string',
            birthMonth: 'string',
            birthYear: 'string',
            minAge: 'string',
        }),
    }),
    isAddonAvailable: bool,
    mobileAgeVerificationOpen: bool,
    handleMobileAgeVerification: func,
    addToCartData: addToCartDataType,
    hasAddons: bool,
    displayAdditionalCompliance: bool,
    featureFlags: shape({
        'is-pdp-v-2-enabled': false,
    }),
    quantity: number,
    components: array,
    ffIsWineVerificationNewApi: bool,
    wineAgeVerify: func.isRequired,
    pdpInlineAddon: bool,
    open: bool,
    setOpen: func.isRequired,
    handleAddToCart: func,
    productBrand: string,
    addonWindow: bool,
    brandWineModelOnAction: bool,
    selectedAddons: array,
    defaultSku: string,
};
CommonProductAgeZipVerify.defaultProps = {
    zipValid: null,
    forceModal: false,
    isAddonAvailable: false,
    addItemAddon: () => {},
    wineValidation: null,
    mobileAgeVerificationOpen: false,
    handleMobileAgeVerification: () => {},
    addToCartData: {},
    hasAddons: false,
    displayAdditionalCompliance: false,
    featureFlags: {},
    quantity: 1,
    components: [],
    ffIsWineVerificationNewApi: false,
    pdpInlineAddon: false,
    open: false,
    handleAddToCart: () => {},
    productBrand: '',
    addonWindow: false,
    brandWineModelOnAction: false,
    selectedAddons: [],
    defaultSku: '',
};

const enhance = compose(
    withRouter,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(CommonProductAgeZipVerify);
