/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { getBrandName } from '../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import findContent from '../queries/findContent';

const useSnipeData = () => {
    const brandName = useSelector(getBrandName);
    const contentType = 'product_overlays';
    const getContentTypeData = {
        brand: brandName,
        contentType,
    };
    const SNIPE_PAGE_QUERY = findContent(getContentTypeData);

    return useQuery(SNIPE_PAGE_QUERY);
};

export default useSnipeData;
