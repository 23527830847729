/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useContext } from 'react';
import {
    object, string, shape, bool, arrayOf, func,
} from 'prop-types';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';
import { clubComponentType } from '../common/types';
import { getPmallSummaryInfo } from '../../../../../../state/ducks/Common/Common-Selectors';
import { CrossMerch } from '../../../CrossMerchContainer/CrossMerchContainer';

const GraphqlProductImageUIComponent = Loadable({
    loader: () => import(/* webpackChunkName: "GraphqlProductImageUIV1" */ './v1/GraphqlProductImageUI'),
    loading: () => <div />,
});
const GraphqlProductImageUIComponentV2 = Loadable({
    loader: () => import(/* webpackChunkName: "GraphqlProductImageUIV2" */ './v2/GraphqlProductImageUI'),
    loading: () => <div />,
});

const GraphqlProductImageUI = ({
    data,
    location,
    productBaseContent,
    selectedSku,
    isClubProduct,
    clubComponents,
    renderSnipe,
    renderHeroImageFlag,
    altImagesContainerStyle,
    pmallSummaryInfo,
    setShippingModalOpen,
    productUrl,
    partNumber,
    isClubPDPV2,
}) => {
    const { featureFlags = {}, presentation_family = '' } = useContext(CrossMerch);
    return (
        featureFlags['is-pdp-v-2-enabled'] ? (
            <GraphqlProductImageUIComponentV2
                // TODO remap to product, data duplicate prop
                data={data}
                location={location}
                presentation_family={presentation_family}
                productBaseContent={productBaseContent}
                selectedSku={selectedSku}
                isClubProduct={isClubProduct}
                clubComponents={clubComponents}
                renderSnipe={renderSnipe}
                renderHeroImageFlag={renderHeroImageFlag}
                altImagesContainerStyle={altImagesContainerStyle}
                pmallSummaryInfo={pmallSummaryInfo}
                setShippingModalOpen={setShippingModalOpen}
                featureFlags={featureFlags}
                presentationFamily={presentation_family}
                productUrl={productUrl}
                partNumber={partNumber}
                isClubPDPV2={isClubPDPV2}
            />
        ) : (
            <GraphqlProductImageUIComponent
                // TODO remap to product, data duplicate prop
                data={data}
                location={location}
                presentation_family={presentation_family}
                productBaseContent={productBaseContent}
                selectedSku={selectedSku}
                isClubProduct={isClubProduct}
                clubComponents={clubComponents}
                renderSnipe={renderSnipe}
                renderHeroImageFlag={renderHeroImageFlag}
                altImagesContainerStyle={altImagesContainerStyle}
                pmallSummaryInfo={pmallSummaryInfo}
                setShippingModalOpen={setShippingModalOpen}
                featureFlags={featureFlags}
                presentationFamily={presentation_family}
                productUrl={productUrl}
                partNumber={partNumber}
                isClubPDPV2={isClubPDPV2}
            />
        )
    );
};
GraphqlProductImageUI.propTypes = {
    data: object.isRequired,
    location: object.isRequired,
    selectedSku: object.isRequired,
    productBaseContent: shape({
        entries: arrayOf(shape({
            products_viewed_section: shape({
                background_color: string,
                copy: string,
                font_color: string,
            }),
        })),
    }),
    isClubProduct: bool,
    clubComponents: clubComponentType,
    renderSnipe: bool,
    renderHeroImageFlag: bool,
    altImagesContainerStyle: string,
    pmallSummaryInfo: shape({
        customizations: shape({
            refnum: string,
            imageURL: string,
        }),
    }).isRequired,
    setShippingModalOpen: func,
    productUrl: string,
    partNumber: string,
    isClubPDPV2: bool,
};

GraphqlProductImageUI.defaultProps = {
    isClubProduct: false,
    clubComponents: [],
    renderSnipe: true,
    renderHeroImageFlag: false,
    altImagesContainerStyle: '',
    productBaseContent: {},
    setShippingModalOpen: () => {},
    productUrl: '',
    partNumber: '',
    isClubPDPV2: false,
};

const mapStateToProps = (state) => ({
    pmallSummaryInfo: getPmallSummaryInfo(state),
});

export default connect(mapStateToProps, null)(GraphqlProductImageUI);
