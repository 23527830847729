/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import { object, string, func } from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Error from '@material-ui/icons/Error';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { clearErrors } from '../../../../../../state/ducks/Common/ducks/Error/Error-Actions';
import { getCartError, getCustomerCare } from '../../../../../../state/ducks/Common/Common-Selectors';

const styles = () => ({
    dialogContainer: {
        padding: '20px 25px 10px 25px',
    },
    errorMessage: {
        backgroundColor: '#FFF',
        fontSize: '15px',
    },
    iconWrapper: {
        float: 'left',
        padding: '0px',
        marginTop: '-3px',
        height: '12px',
    },
    buttonAction: {
        padding: '10px 0px 5px',
        textAlign: 'center',
    },
});

const MaxCartErrorMessage = ({
    cartError, clearCartError, classes, customerCarePhoneNo,
}) => {
    const [openModel, handleOpenClose] = useState(false);

    useEffect(() => {
        if (cartError === 'MAX_CART_SIZE_REACHED') {
            handleOpenClose(true);
        } else {
            handleOpenClose(false);
        }
    }, [cartError]);

    const handleCloseErrorMessage = () => {
        clearCartError({
            errType: 'cart',
            field: 'error',
        });
        handleOpenClose(false);
    };

    return (
        <>
            {openModel ? (
                <Dialog
                    open={openModel}
                >
                    <Grid className={classes.dialogContainer} container>
                        <Grid item xs={12}>
                            <span className={classes.iconWrapper}>
                                <Error color="error" />
                            </span>
                            <span className={classes.errorMessage}>
                                <span>Your cart is full. Please complete this order to clear your cart before you start shopping again. For assistance, please contact customer service at</span>
                                &nbsp;<a href={`tel:${customerCarePhoneNo}`}>{customerCarePhoneNo}</a>
                            </span>
                        </Grid>
                        <Grid className={classes.buttonAction} item xs={12}>
                            <Button
                                disableElevation
                                variant="contained"
                                onClick={handleCloseErrorMessage}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </Dialog>
            ) : null }
        </>
    );
};

MaxCartErrorMessage.propTypes = {
    classes: object.isRequired,
    clearCartError: func.isRequired,
    cartError: string,
    customerCarePhoneNo: string.isRequired,
};

MaxCartErrorMessage.defaultProps = {
    cartError: '',
};

const mapStateToProps = (state) => ({
    cartError: getCartError(state),
    customerCarePhoneNo: getCustomerCare(state),
});

const mapDispatchToProps = (dispatch) => ({
    clearCartError: bindActionCreators(clearErrors, dispatch),
});

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(MaxCartErrorMessage);
