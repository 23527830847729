/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string,
    object,
    func,
} from 'prop-types';
import Loadable from 'react-loadable';

const ProductVerificationComponent = Loadable({
    loader: () => import(/* webpackChunkName: "ProductVerificationV1" */ './v1/ProductVerification'),
    loading: () => <div />,
});
const ProductVerificationComponentV2 = Loadable({
    loader: () => import(/* webpackChunkName: "ProductVerificationV2" */ './v2/ProductVerification'),
    loading: () => <div />,
});

const ProductVerification = ({
    zipField,
    dayErrorMessage,
    monthErrorMessage,
    yearErrorMessage,
    zipFieldRef,
    getBirthDay,
    getBirthMonth,
    getBirthYear,
    handleAgeVerification,
    validateFields,
    handleZipChange,
    displayZipValidCSSCheck,
    validateZip,
    birthDay,
    birthMonth,
    birthYear,
    featureFlags,
}) => (featureFlags['is-pdp-v-2-enabled'] ? (
    <ProductVerificationComponentV2
        zipField={zipField}
        dayErrorMessage={dayErrorMessage}
        monthErrorMessage={monthErrorMessage}
        yearErrorMessage={yearErrorMessage}
        zipFieldRef={zipFieldRef}
        getBirthDay={getBirthDay}
        getBirthMonth={getBirthMonth}
        getBirthYear={getBirthYear}
        handleAgeVerification={handleAgeVerification}
        validateFields={validateFields}
        handleZipChange={handleZipChange}
        displayZipValidCSSCheck={displayZipValidCSSCheck}
        validateZip={validateZip}
        birthDay={birthDay}
        birthMonth={birthMonth}
        birthYear={birthYear}
    />
) : (
    <ProductVerificationComponent
        zipField={zipField}
        dayErrorMessage={dayErrorMessage}
        monthErrorMessage={monthErrorMessage}
        yearErrorMessage={yearErrorMessage}
        zipFieldRef={zipFieldRef}
        getBirthDay={getBirthDay}
        getBirthMonth={getBirthMonth}
        getBirthYear={getBirthYear}
        handleAgeVerification={handleAgeVerification}
        validateFields={validateFields}
        handleZipChange={handleZipChange}
        displayZipValidCSSCheck={displayZipValidCSSCheck}
        validateZip={validateZip}
        birthDay={birthDay}
        birthMonth={birthMonth}
        birthYear={birthYear}
    />
));

ProductVerification.propTypes = {
    zipField: string.isRequired,
    dayErrorMessage: string.isRequired,
    monthErrorMessage: string.isRequired,
    yearErrorMessage: string.isRequired,
    zipFieldRef: func.isRequired,
    birthDay: string.isRequired,
    birthMonth: string.isRequired,
    birthYear: string.isRequired,
    getBirthDay: func.isRequired,
    getBirthMonth: func.isRequired,
    getBirthYear: func.isRequired,
    handleAgeVerification: func.isRequired,
    validateFields: func.isRequired,
    handleZipChange: func.isRequired,
    displayZipValidCSSCheck: func.isRequired,
    validateZip: func.isRequired,
    featureFlags: object,
};
ProductVerification.defaultProps = {
    featureFlags: {},
};

export default ProductVerification;
