/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { array, object } from 'prop-types';
import React, { useEffect } from 'react';
import QueryString from 'qs';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import {
    getOrderContainsClubItemPayByCreditCard,
    getOrderContainsSubscriptionItem,
    getPassportItem,
    getOrderTotalBalance,
} from '../../../../../state/ducks/Common/Common-Selectors';
import getPrice from '../../../../helpers/getPrice/getPrice';

const dollarToCent = (amount) => Math.ceil(amount * 100);

const WidgetKlaranBadge = ({ productPrices }) => {
    const finalPrice = getPrice(productPrices);
    const priceInCent = dollarToCent(finalPrice.value);

    useEffect(() => {
        if (typeof window !== 'undefined' && window.KlarnaOnsiteService?.refresh) {
            window.KlarnaOnsiteService.refresh();
        }
    }, [productPrices, priceInCent]);

    return (
        <div style={{ padding: '5px 0px' }}>
            <klarna-placement
                data-key="credit-promotion-badge"
                data-locale="en-US"
                data-purchase-amount={priceInCent}
            />
        </div>
    );
};

WidgetKlaranBadge.propTypes = {
    productPrices: array.isRequired,
};

const KlarnaCreditPromotionBadge = ({
    selectedSku,
    productSkuPrices,
}) => {
    const history = useHistory();
    const searchTerms = QueryString.parse(history.location.search.replace(/^\?/, ''));
    const hasSubscriptionParam = searchTerms?.subscription?.toLowerCase && searchTerms.subscription.toLowerCase() === 'basic';
    const discoveryViews = ['discovery', 'monthonly', 'monthonlypayfull'];
    const isTypeDiscovery = discoveryViews.includes(selectedSku?.subscriptions?.type?.toLowerCase?.());

    // if the type is discovery by default it proceed as a default selected subscription
    const subscriptionProductSelected = hasSubscriptionParam || (isTypeDiscovery);

    const selectedSkuId = selectedSku?.id;
    const selectedProductPrice = productSkuPrices?.filter((product) => product.id === selectedSkuId) || [];
    const skuPrice = selectedSku.prices?.length > 0 ? selectedSku.prices : [];
    const productPrices = selectedProductPrice?.[0]?.prices?.length > 0 ? selectedProductPrice?.[0]?.prices : skuPrice;
    const paymentMethodIndex = selectedSku?.paymentMethod || -1;

    const orderTotal = useSelector(getOrderTotalBalance);
    const passportItem = useSelector(getPassportItem);
    const ffKlarnaPaymentEnabled = useSelector((state) => getFeatureFlag('is-klarna-payment-enabled')(state));
    const ffKlarnaProductPagePriceBadgeEnabled = useSelector((state) => getFeatureFlag('is-klarna-payment-pdp-price-badge-enabled')(state));

    const isClubItemInCart = useSelector(getOrderContainsClubItemPayByCreditCard);
    const subscriptionItemInCart = useSelector(getOrderContainsSubscriptionItem);
    const klarnaAllowedAmount = useSelector((state) => getFeatureFlag('klarna-allowed-min-and-max-amount')(state));
    const maxAmount = klarnaAllowedAmount.max || 1000;

    if (ffKlarnaPaymentEnabled
        && ffKlarnaProductPagePriceBadgeEnabled
        && Number(paymentMethodIndex) !== 0
        && !subscriptionProductSelected
        && !isClubItemInCart
        && subscriptionItemInCart.length === 0
        && orderTotal < maxAmount
        && productPrices?.length > 0
        && passportItem.length === 0) {
        return <WidgetKlaranBadge productPrices={productPrices} />;
    }

    return null;
};

KlarnaCreditPromotionBadge.propTypes = {
    selectedSku: object.isRequired,
    productSkuPrices: array,
};

KlarnaCreditPromotionBadge.defaultProps = {
    productSkuPrices: [],
};

export default KlarnaCreditPromotionBadge;
