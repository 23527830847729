/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { arrayOf, bool, object } from 'prop-types';

const useStyles = makeStyles(() => ({
    lockedOverlay: {
        height: '100%',
        width: '100%',

        opacity: '.3',
    },

    unlockedOverlay: {
        height: '100%',
        width: '100%',
    },

    lockedIcon: {
        position: 'absolute',
        color: 'white',
        top: '45%',
        left: '45%',
        zIndex: '10',
    },
}));

const LockOverlay = (props) => {
    const {
        children,
        shouldDisplay,
    } = props;

    const classes = useStyles();

    return (
        <>
            {shouldDisplay && <LockIcon className={classes.lockedIcon} />}
            <div className={shouldDisplay ? classes.lockedOverlay : classes.unlockedOverlay}>
                {children}
            </div>
        </>

    );
};

LockOverlay.propTypes = {
    children: arrayOf(object).isRequired,
    shouldDisplay: bool.isRequired,
};

export default LockOverlay;
