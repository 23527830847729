/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React from 'react';
import { string, shape, number } from 'prop-types';
import { useSelector } from 'react-redux';
import SnipeRenderCMS from './SnipeRenderCMS';
import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import useSnipeData from '../../../../../gql/hooks/useSnipeData';

const SnipeRenderQuery = ({
    partNumber, categoryId, productType, imageContainerRef,
}) => {
    const cmsSnipeEnabledFlag = useSelector(getFeatureFlag('is-cms-snipe-enabled'));
    if (!cmsSnipeEnabledFlag || !partNumber) return <></>;
    const { data } = useSnipeData();
    if (data?.findContent?.content?.entries?.length > 0) {
        return (
            <SnipeRenderCMS
                data={data.findContent.content?.entries}
                partNumber={partNumber}
                categoryId={categoryId}
                productType={productType}
                imageContainerRef={imageContainerRef}
            />
        );
    }
    return <></>;
};
SnipeRenderQuery.propTypes = {
    partNumber: string,
    categoryId: string,
    imageContainerRef: shape({
        current: shape({
            clientWidth: number,
        }),
    }),
    productType: string,
};
SnipeRenderQuery.defaultProps = {
    partNumber: '',
    categoryId: '',
    imageContainerRef: {},
    productType: '',
};
export default (SnipeRenderQuery);
