/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { bool } from 'prop-types';
import { getFeatureFlags, getPresentationFamily } from '../../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getActiveABTests } from '../../../../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';

const useStyles = makeStyles(({
    passportEligibleImage: {
        width: '40%',
        display: 'block',
        marginBottom: '3px',
        '@media screen and (max-width: 1440px)and (min-width:1024px)': {
            width: '40%',
        },
        '@media screen and (max-width: 768px)and (min-width:320px)': {
            width: '60%',
        },
    },
    abTestPassportEligibleImage: {
        width: '123px',
        display: 'block',
    },
    abTestMarginBottom: {
        marginBottom: '2px',
    },
    restructurePassportImage: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '4px',
        '& span': {
            fontSize: '14px',
            lineHeight: '20px',
            marginLeft: '4px',
            letterSpacing: '0.5px',
            '@media only screen and (max-width: 768px)': {
                fontSize: '12px',
                lineHeight: '18px',
            },
        },
        '& img': {
            width: '15px',
            height: '15px',
            '@media only screen and (max-width: 768px)': {
                width: '12px',
                height: '12px',
            },
        },
    },
}));

const PassportImage = ({ isPassportEligible, showSimpleProductRedesignAbTest, collectionRestructureEnabled }) => {
    const classes = useStyles();
    const presentationFamily = useSelector(getPresentationFamily);
    const isFood = presentationFamily === 'food';
    const featureFlags = useSelector(getFeatureFlags);
    const activeABTests = useSelector(getActiveABTests);
    /**
     * @description - Passport elagibiility banner
     * - Display banner if product is eligable and flag is enabled
     * @return {bool}
     */
    const renderPassportBanner = () => {
        if (!isFood && isPassportEligible && featureFlags['is-prime-free-ship-icon-enabled'] === true && activeABTests?.passport_banner_collection !== 'hide') return true;
        if (isFood && isPassportEligible && featureFlags['is-passport-eligible-banner'] === true && activeABTests?.passport_banner_collection !== 'hide') return true;
        return false;
    };

    const restructurePassport = () => (
        <div className={classes.restructurePassportImage}>
            <img
                src="https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt2d65ed0b1e29bd1f/662204fa81c88460f137d5da/passport.svg"
                alt="Passport Eligible"
            />
            <span>Passport Eligible</span>
        </div>
    );
    /*  ab test
        if no longer needed remove */
    // isMOBILE ?
    if (activeABTests?.mobile_simple_product_redesign && showSimpleProductRedesignAbTest) {
        return (
            <div>
                {collectionRestructureEnabled
                    ? restructurePassport()
                    : (
                        <img
                            className={`${classes.abTestMarginBottom} ${classes.abTestPassportEligibleImage}`}
                            src="https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt8d4549d3cac15860/61e09d4f2e109d6c649d4aa4/PP_EligibleIcon.svg"
                            alt="Passport Eligible"
                            height="15"
                            width="142"
                        />
                    )}
            </div>
        );
    }
    /*  END ab test
        if no longer needed remove */
    if (renderPassportBanner()) {
        return (
            <>
                {collectionRestructureEnabled
                    ? restructurePassport()
                    : (
                        <img
                            className={classes.passportEligibleImage}
                            src="https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt8d4549d3cac15860/61e09d4f2e109d6c649d4aa4/PP_EligibleIcon.svg"
                            alt="Passport Eligible"
                            height="15"
                            width="142"
                        />
                    )}
            </>
        );
    }

    return null;
};

PassportImage.propTypes = {
    isPassportEligible: bool.isRequired,
    showSimpleProductRedesignAbTest: bool.isRequired,
    collectionRestructureEnabled: bool,
};
PassportImage.defaultProps = {
    collectionRestructureEnabled: false,
};

export default PassportImage;
