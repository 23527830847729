/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useEffect } from 'react';
import {
    string, bool, func, shape,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';

import {
    Button, Dialog, Typography, Grid,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { makeStyles } from '@material-ui/core/styles';

import { getUserSubmittedProductFilterZipcode } from '../../../../../state/ducks/App/App-Selectors';
import { getBrand, getFeatureFlag, getPresentationFamily } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import noop from '../../../../helpers/noop';

const useStyles = makeStyles((theme) => ({
    buttonWrapperLink: {
        textDecoration: 'none',
        borderRadius: '4px',
    },
    mainConainer: {
        padding: '10px 20px 20px 20px',
    },
    redirectButton: {
        width: '100%',
        fontSize: '15px',
        background: theme.palette.cms?.flowerPdpButton || '#61C278',
        color: theme.palette.cms?.white || '#fff',
        '&:hover': {
            background: theme.palette.cms?.flowerPdpButton || '#61C278',
            color: theme.palette.cms?.white || '#fff',
        },
    },
    modalPaper: {
        padding: '12px',
        maxWidth: '320px',
        position: 'absolute',
        border: `2px solid ${theme.palette.cms?.black || '#000'}`,
        top: 'auto',
        margin: 0,
        left: 'auto',
        '@media screen and (min-width: 601px)': {
            top: '40%',
            left: '50%',
        },
        '@media screen and (min-width: 769px)': {
            left: '55%',
        },
        '@media screen and (min-width: 1025px)': {
            top: '50%',
            left: '60%',
        },
    },
    outerDialog: {},
    infoText: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        paddingBottom: '10px',
    },
    closeButton: {
        textAlign: 'right',
        cursor: 'pointer',
        fontSize: '28px',
        margin: '-15px -15px 0 0',
    },
    errorIcon: {
        color: 'red',
        width: '40px',
        height: 'auto',
    },
    errorContainer: {
        margin: '0 auto',
    },
}));

const GraphqlProductNotAvailableModal = ({
    isOpen,
    onClose,
    onNavigate,
    productNotAvailable,
    productName,
    trackEvent,
    isFromQuickview,
    zipcode,
    selectedSku,
}) => {
    const classes = useStyles();
    const location = useLocation();
    const userSubmittedZip = useSelector(getUserSubmittedProductFilterZipcode);
    const isFlowerBrand = useSelector(getPresentationFamily) === 'flower';
    const { cache } = useApolloClient();
    const brand = useSelector(getBrand);
    const ffWhichBrandUrl = useSelector(getFeatureFlag('which-bestseller-url-for-unavailable-product'));
    const isCrossBrandProduct = selectedSku?.brandId ? selectedSku?.brandId !== brand?.id : false;
    let crossBrandUrl = null;
    if (ffWhichBrandUrl && typeof ffWhichBrandUrl === 'object' && Object.keys(ffWhichBrandUrl)?.length > 0 && isCrossBrandProduct) {
        crossBrandUrl = ffWhichBrandUrl[brand?.domain] || null;
    }

    useEffect(() => {
        if (isOpen) {
            cache.evict({
                id: 'ROOT_QUERY',
                field: 'findCategoryById',
            });
            cache.gc();
        }
    }, [cache, isOpen]);
    useEffect(() => {
        if (isOpen) {
            trackEvent({ eventCategory: 'Product Page', eventAction: 'Product not Available Message Shown', eventLabel: `${productName} - ${userSubmittedZip}` });
        }
    }, [isOpen]);
    const buttonTitle = productNotAvailable?.cta_text ?  `${productNotAvailable?.cta_text} ${isFlowerBrand ? zipcode : ''}` : `SEE PRODUCTS FOR ${zipcode}`;
    const modalText = productNotAvailable?.product_not_available_text || 'We are sorry, the product you have selected is not available';
    const buttonLink = crossBrandUrl || productNotAvailable?.cta_link?.href || '/bestsellingflowers';
    let redirectLocation;
    if (location.state && location.state.fromCategoryPage) {
        if (location.state.fromCategoryPage === true) {
            redirectLocation = location.state.prevUrl;
        }
    } else {
        redirectLocation =  buttonLink;
    }
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            className={classes.outerDialog}
            hideBackdrop
            classes={{
                paper: classes.modalPaper,
            }}
            data-testid="pna-modal"
        >
            <Grid className={classes.mainConainer}>
                <Grid
                    className={classes.topBar}
                    container
                    direction="row"
                    justify="space-between"
                >
                    <Grid item className={classes.errorContainer}>
                        {' '}
                        <ErrorOutlineIcon className={classes.errorIcon} />
                    </Grid>
                    <Grid item className={classes.closeButton} onClick={onClose}>
                        <span>X</span>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography className={classes.infoText}>
                        {modalText}
                    </Typography>
                </Grid>
                <Link
                    onClick={() => {
                        onNavigate();
                        trackEvent({ eventCategory: 'Product Page', eventAction: 'Product not Available Click to see Products', eventLabel: `${productName} - ${userSubmittedZip}` });
                    }}
                    to={redirectLocation}
                    className={classes.buttonWrapperLink}
                >
                    <Button variant="outlined" className={classes.redirectButton}>
                        {buttonTitle} {!isFromQuickview ? userSubmittedZip : ''}
                    </Button>
                </Link>
            </Grid>
        </Dialog>
    );
};

GraphqlProductNotAvailableModal.propTypes = {
    isOpen: bool.isRequired,
    onClose: func.isRequired,
    onNavigate: func,
    productNotAvailable: shape({
        product_not_available_text: string,
        cta_text: string,
        cta_link: shape({
            href: string,
        }),
    }),
    trackEvent: func,
    productName: string,
    isFromQuickview: bool,
    zipcode: string,
    selectedSku: shape({
        id: string.isRequired,
        name: string.isRequired,
    }),
};
GraphqlProductNotAvailableModal.defaultProps = {
    productNotAvailable: {},
    trackEvent: () => {},
    onNavigate: noop,
    productName: '',
    isFromQuickview: false,
    zipcode: '',
    selectedSku: {},
};

export default GraphqlProductNotAvailableModal;
