/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import {
    FormControl, Input, InputLabel, Typography, Grid, Select, MenuItem, IconButton,
} from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { KeyboardArrowDown, CalendarToday } from '@material-ui/icons';
import {
    object, string, shape, arrayOf, bool, func,
} from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { bindActionCreators } from 'redux';
import GraphqlCalendarContainer from '../../../../GraphqlProductPage/Partials/GraphqlPDPCalendar/GraphqlCalendarContainer';
import ProductAgeVerificationModalComponent from '../../../../GraphqlProductPage/Partials/ProductAgeVerificationModal';
import GraphqlProductNotAvailableModal from '../../../../GraphqlProductPage/Partials/GraphqlProductNotAvailableModal';
import { trackEvent as trackEventAction } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

import noop from '../../../../../../helpers/noop';
import { getBrand } from '../../../../../../../state/ducks/Member/ducks/Common/Common-Selectors';

import { setUserSubmittedProductFilterZipcode as setUserSubmittedProductFilterZipcodeAction } from '../../../../../../../state/ducks/App/App-Actions';

const useStyle = makeStyles((theme) => ({

    DeliverySelectorsGrid: {
        marginBottom: '1em',
        justifyContent: 'space-between',
        [theme.breakpoints.down(1015)]: {
            marginBottom: '1em',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },

    },

    CommonFormControlStyle: {
        [theme.breakpoints.down(1015)]: {
            width: '75%',
        },
    },

    /**
         *
         * text input field
         *
         */
    TextInputRoot: {
        marginTop: '22px !important',
        border: '1.5px solid #dbdbdb',
        borderRadius: '4px',

        '&:hover': {
            backgroundColor: 'transparent',
            border: `1.5px solid ${theme.palette.colorPrimary}`,
            cursor: 'pointer',
        },
    },

    TextInputField: {
        padding: '5px 0',
        paddingLeft: '3px',
        fontSize: '.8em',
        textAlign: 'center',
        '&::placeholder': {
            color: theme.palette.common.black,
            opacity: 1,
        },
    },

    RequiredAsterik: {
        color: theme.palette.common.red,
    },

    ZipcodeInputValid: {
        '&::after': {
            right: '5px',
            position: 'absolute',
            zIndex: '999',
            backgroundColor: theme.palette.colorSuccess,
            color: theme.palette.white,
            textAlign: 'center',
            content: '"✓"',
            fontSize: '9px',
            fontWeight: 'bold',
            lineHeight: '17px',
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            animation: 'fadeZoomIn 0.3s ease-in',
            display: 'inline-block', // IE 11
        },
    },

    ZipcodeInputInvalid: {
        '&::before': {
            position: 'absolute',
            zIndex: '999',
            color: theme.palette.cms?.white || '#fff',
            lineHeight: '25px',
            fontSize: '11px',
            fontWeight: '300',
            content: '"Invalid zip code"',
            bottom: '-25px',
            left: 0,
            paddingLeft: '7px',
            width: 'calc(100% - 8px)',
            height: '25px',
            backgroundColor: theme.palette.cms?.red || '#ff0000',
            transition: 'opacity 0.3s ease-in',
            opacity: 1,
        },
    },
    ZipcodeInputEmpty: {
        '&::before': {
            position: 'absolute',
            zIndex: '999',
            color: theme.palette.cms?.white || '#fff',
            lineHeight: '25px',
            fontSize: '11px',
            fontWeight: '300',
            content: '"Input a zip code"',
            bottom: '-25px',
            left: 0,
            paddingLeft: '7px',
            width: 'calc(100% - 8px)',
            height: '25px',
            backgroundColor: theme.palette.cms?.red || '#ff0000',
            transition: 'opacity 0.3s ease-in',
            opacity: 1,
        },
    },

    /**
         *
         * Dropdown residence type field
         *
         */

    DropdownFormControlRoot: {
        width: '150px',
        backgroundColor: 'transparent',

        [theme.breakpoints.down(1015)]: {
            width: '75%',
        },
    },

    InputLabelShrinkDropdown: {
        transform: 'translate(0px, -3px) scale(0.75) !important',
        width: '200px !important',
    },

    DropdownSelectorRoot: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        fontSize: '.9rem',
        padding: '3px 0',
        paddingRight: '24px !important', // same as width of icon
        paddingLeft: '3px',
        textAlign: 'center',
        overflowX: 'hidden',
        borderBottom: 'unset',
        border: '1.5px solid #dbdbdb !important',
        borderRadius: '4px',

        '&:hover': {
            backgroundColor: 'transparent',
            cursour: 'pointer',
            border: `1.5px solid ${theme.palette.colorPrimary} !important`,
        },

        '&:focus': {
            backgroundColor: 'transparent',
        },
    },

    DropdownSelectorIconArrow: {
        backgroundColor: 'transparent',
        top: 'unset',
        right: '5px',
        height: '100%',
        width: '24px',
    },

    DropdownSelectorNativeInput: {
        height: 0,
    },

    DropdownSelectorIconCalendar: {
        backgroundColor: 'transparent',
        top: 'unset',
        height: '100%',
        width: '18px',
    },

    QuickviewSelectorItem: {
        textAlign: 'center',
    },

    DropdownItemRoot: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

    /**
     * Date selector icon button
     */

    InputLabelShrinkButton: {
        transform: 'translate(0px, 3px) scale(0.75) !important',
        width: '200px !important',
    },

    DateIconButtonRoot: {
        minWidth: '150px',
        height: '30px',
        marginTop: '22px',
        border: 'solid 1px #dbdbdb',
        borderRadius: '4px',

        '&:hover': {
            backgroundColor: 'transparent',
            cursour: 'pointer',
            border: `1.5px solid ${theme.palette.colorPrimary}`,
        },
    },
    DateIconButtonEmpty: {
        '&::before': {
            position: 'absolute',
            zIndex: '999',
            color: theme.palette.cms?.white || '#fff',
            lineHeight: '25px',
            fontSize: '11px',
            fontWeight: '300',
            content: '"Select a delivery date"',
            bottom: '-25px',
            left: 0,
            paddingLeft: '7px',
            width: 'calc(100% - 8px)',
            height: '25px',
            backgroundColor: theme.palette.cms?.red || '#ff0000',
            transition: 'opacity 0.3s ease-in',
            opacity: 1,
        },
    },

    DateIconButtonContents: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.black,
        marginRight: '16px',
    },

    DateIconButtonText: {
        fontSize: '.9rem',
    },

    CalendarIconRoot: {
        position: 'absolute',
        right: '6px',
        top: '0',
        width: '18px',
        backgroundColor: 'transparent',
        color: 'rgba(0, 0, 0, 0.54)',
        marginLeft: '10px',
    },
}));

// precompile regex, should match A1A 1A1 || A1A1A1
const canadianZipcodeFormat = new RegExp(/^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[- ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}/);
const zipIsCanadian = (zipcode) => canadianZipcodeFormat.test(zipcode);
const formatCanadianZipcode = (zipcode) => {
    // handle zipcode conversion for canda
    const zipSplit = zipcode.replace(/\s/gi, '').toUpperCase().split('');
    zipSplit.splice(3, 0, ' ');
    return zipSplit.join('');
};

const DeliverySelectors = (props) => {
    const {
        data,
        brand,
        trackEvent,
        setUserSubmittedProductFilterZipcode,

        locationTypeOptions, // local
        locationType, // local
        setLocationType, // local

        zipcode, // local
        setZipcode, // local
        validateProductFilterZipcode, // redux
        clearValidatedZipcode, // redux
        isProductFilterZipcodeValid, // redux

        calendarData, // constructed in parent

        // initial state pointers for validation in handleCalendarClick
        productSelectorInitialState,
        zipcodeInitialState,

        // ephemeral/local error state handling
        error,
        accountError,
        errorTypes,
        resolveError,

        // display value for button
        date,
        setDate,

        // selectedSku.ageVerifyFlag = "true"
        ageDetails,
        setAgeDetails,

        setAddToCartData,
        showProductUnavailable,
        setShowProductUnavailable,

        categoryId,
        categoryPath,
        categoryName,
    } = props;

    const { name } = data?.findProductPageByPartNumber?.product || {};

    const classes = useStyle();

    // more ephemeral state, show the calendar modal or not
    const [showCalendar, setShowCalendar] = useState(false);
    const [displayZipPlaceholder, setDisplayZipPlaceholder] = useState(true);

    /**
     * @description Handles the zipcode change event.
     * @param {object} event
     */
    const handleZipcodeChange = (event) => {
        /**
         * used when a zipcode is ready to be validated (length >= 5)
         * @define {validateProductFilterZipcode: func} saga to validate
         * @define {clearValidatedZipcode: func} action to clear
         * note: this spawns the saga to trigger the api call to validate the zipcode
         *
         * ephemeral state, what is actually displayed in the field
         * @define {setZipcode: func} set the local state found in QuickviewDialog.js
         * @define {resolveError: func} localized error resolver from parent
         */

        let { value } = event.target;

        const potentiallyValidZip = value.length >= 5;
        const isCanadianZip = value.length >= 6 && zipIsCanadian(value);
        const impossibleZip = value.length < 5;

        // if the zip is canadian, enforce formatting for validation
        if (isCanadianZip) value = formatCanadianZipcode(value);

        if (potentiallyValidZip) {
            resolveError(errorTypes.MISSING_ZIPCODE);
        }

        if (potentiallyValidZip || isCanadianZip) {
            validateProductFilterZipcode(value);
        } else if (impossibleZip) {
            clearValidatedZipcode();
        }

        if (error[errorTypes.MISSING_OR_INVALID_ZIPCODE]) resolveError(errorTypes.MISSING_OR_INVALID_ZIPCODE);
        // local state
        setZipcode(value);
    };

    /**
     * @description Handles the change event for location type dropdown selector.
     * @param {object} event
     */
    const handleLocationTypeChange = (event) => {
        const { value } = event.target;

        /**
         * note:
         * There is a location type that is set in redux that
         * should be updated but doing so rerenders the entire page.
         *
         * It looks like its just a parameter to help decide
         * which products to show. Skip it for now as I dont think
         * it will have a major impact or if we even want quickview
         * to affect that stuff.
         */

        // local
        setLocationType(value);
    };

    /**
     * @description check the neighboring selections and ensure the modal
     * is filled out with valid entries before opening the calendar. this is done
     * so the calls to get the calendar wont error out.
     *
     * sets errors as needed
     */
    const handleCalendarClick = () => {
        const hasProductSelection = calendarData.selectedItemId !== productSelectorInitialState.id;
        const hasGoodZipcode = calendarData.zipCode !== zipcodeInitialState && isProductFilterZipcodeValid && zipcode.length >= 5;
        const requiresAgeVerification = calendarData.selectedSku.ageVerifyFlag === 'true' && !ageDetails.hasResponded;
        // locationType is set to a valid initial value

        // ensure required selections/data is present
        if (!hasProductSelection || !hasGoodZipcode) {
            // determine the error to set
            if (!hasProductSelection && hasGoodZipcode) {
                accountError(errorTypes.MISSING_PRODUCT);
                return;
            }

            if (hasProductSelection && !hasGoodZipcode) {
                accountError(errorTypes.MISSING_OR_INVALID_ZIPCODE);
                return;
            }

            // if its not one of the above, its both
            accountError([errorTypes.MISSING_PRODUCT, errorTypes.MISSING_OR_INVALID_ZIPCODE]);
            return;
        }

        /**
         * Once we reach this point, we need to follow the
         * age verify workflow similar to pdp for products that contain alcohal.
         */
        if (requiresAgeVerification) {
            setAgeDetails({
                ...ageDetails,
                show: true,
            });
            return;
        }

        calendarData.setCalendarDataLoadingFlag(true);
        setShowCalendar(true);
        resolveError(errorTypes.MISSING_DELIVERY_DATE);
    };

    /**
     * @description For products that require age verification, this function will be called
     * when the process is canceled or finished.
     * @param {string} day
     * @param {string} month
     * @param {string} year
     *
     * @note The age verification process interupts the show calendar workflow in this component.
     * If we get a good response (completion) then show the calendar.
     */
    const handleAgeVerificationFinish = (birthDay, birthMonth, birthYear) => {
        if (!birthDay || !birthMonth || !birthYear) {
            // just hide the modal, user canceled
            setAgeDetails({
                ...ageDetails,
                show: false,
            });
            return;
        }

        setAgeDetails({
            show: false,
            hasResponded: true,
            birthDay,
            birthMonth,
            birthYear,
            minAge: '0',
        });

        calendarData.setCalendarDataLoadingFlag(true);
        setShowCalendar(true);
    };

    /**
     * @description sneak into the calendar component and grab the date
     * from the select date function. This is the exact object
     * that is passed to the addToCart action. Use this to grab the aggregated data
     * and seperate out the display value and addToCart data.
     * @param {object} data
     */
    // eslint-disable-next-line no-shadow
    const handleSelectDate = (data) => {
        let displayDate = new Date(data.date);
        displayDate = displayDate.toLocaleDateString(undefined, {
            weekday: 'long', month: 'short', day: 'numeric',
        });
        setDate(displayDate); // for display purposes only
        setAddToCartData(data); // for addToCart button
    };

    /**
     * @description Function to handle when user clicks on
     * the navigation button on the product unavailable link.
     */
    const handleProductNotAvailableNavigate = () => {
        setUserSubmittedProductFilterZipcode(zipcode.trimEnd());
    };

    const hasZipcodeError = error[errorTypes.MISSING_OR_INVALID_ZIPCODE];
    const isValidZipcode = zipcode.length >= 5 && isProductFilterZipcodeValid;
    const isInvalidZipcode = (zipcode.length >= 5 && !isProductFilterZipcodeValid) || hasZipcodeError;

    /**
     * resolve styling, used to mimic pdp page
     * which has error message and green checkmark implemented using css
     */
    let zipcodeInputStyles = `${classes.TextInputRoot}`;
    let dateIconButtonStyles = `${classes.DateIconButtonRoot}`;
    if (isValidZipcode) zipcodeInputStyles += ` ${classes.ZipcodeInputValid}`;
    if (isInvalidZipcode) zipcodeInputStyles += ` ${classes.ZipcodeInputInvalid}`;
    if (error[errorTypes?.MISSING_ZIPCODE]) zipcodeInputStyles += ` ${classes.ZipcodeInputEmpty}`;
    if (error[errorTypes?.MISSING_DELIVERY_DATE]) dateIconButtonStyles += ` ${classes.DateIconButtonEmpty}`;

    let productNotAvaialble = {
        cta_text: 'SEE PRODUCTS FOR',
        cta_link: '',
    };
    if (data?.findProductPageByPartNumber?.defaultContent?.entries?.[0]?.product_not_available) {
        productNotAvaialble = data?.findProductPageByPartNumber?.defaultContent?.entries?.[0]?.product_not_available;
    }

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            classes={{
                root: classes.DeliverySelectorsGrid,
            }}
        >

            <FormControl
                classes={{
                    root: classes.CommonFormControlStyle,
                }}
            >
                <InputLabel
                    htmlFor="quickview_zipcode"
                    shrink
                >
                    <Typography variant="body2">
                        {'Where\'s it going?'}
                        <span className={classes.RequiredAsterik}>*</span>
                    </Typography>
                </InputLabel>
                <Input
                    disableUnderline
                    required
                    id="quickview_zipcode"
                    type="text"
                    placeholder={displayZipPlaceholder ? 'Delivery zip code' : ''}
                    classes={{
                        root: zipcodeInputStyles,
                        input: classes.TextInputField,
                        formControl: classes.InputFormControl,
                    }}
                    value={zipcode}
                    onFocus={() => setDisplayZipPlaceholder(false)}
                    onBlur={() => setDisplayZipPlaceholder(true)}
                    onChange={handleZipcodeChange}
                />
            </FormControl>

            <FormControl
                margin="dense"
                classes={{
                    root: classes.DropdownFormControlRoot,
                }}
            >
                <InputLabel
                    htmlFor="quickview_location_type"
                    shrink
                    classes={{
                        shrink: classes.InputLabelShrinkDropdown,
                    }}
                >
                    <Typography variant="body2">Location Type<span className={classes.RequiredAsterik}>*</span></Typography>
                </InputLabel>
                <Select
                    disableUnderline
                    IconComponent={KeyboardArrowDown}
                    classes={{
                        root: classes.DropdownSelectorRoot,
                        icon: classes.DropdownSelectorIconArrow,
                        nativeInput: classes.DropdownSelectorNativeInput,
                    }}
                    value={locationType}
                    onChange={handleLocationTypeChange}
                >
                    {locationTypeOptions.map((loc, index) => {
                        const key = `${loc.name}-${index}`;
                        return (
                            <MenuItem
                                key={key}
                                classes={{ root: classes.DropdownItemRoot }}
                                value={loc}
                            >{loc.name}
                            </MenuItem>
                        );
                    })}

                </Select>
            </FormControl>

            <FormControl
                classes={{
                    root: classes.CommonFormControlStyle,
                }}
            >
                <InputLabel
                    htmlFor="quickview_date_picker"
                    shrink
                    classes={{
                        shrink: classes.InputLabelShrinkButton,
                    }}
                >
                    <Typography variant="body2">
                        When should it arrive?<span className={classes.RequiredAsterik}>*</span>
                    </Typography>
                </InputLabel>
                <IconButton
                    id="quickview_date"
                    value="testing"
                    onClick={handleCalendarClick}
                    classes={{
                        root: dateIconButtonStyles,
                        label: classes.DateIconButtonContents,
                    }}
                >
                    <Typography
                        variant="body2"
                        classes={{
                            body2: classes.DateIconButtonText,
                        }}
                    >
                        {date}
                    </Typography>
                    <CalendarToday
                        classes={{
                            root: classes.CalendarIconRoot,
                        }}
                    />
                </IconButton>
            </FormControl>

            <ProductAgeVerificationModalComponent
                setCalendarDataLoadingFlag={noop}
                isOpen={calendarData.ageDetails.show}
                handleAgeVerificationClose={handleAgeVerificationFinish}
                getAgeVerificationDetails={handleAgeVerificationFinish}
            />

            {showProductUnavailable && zipcode && (
                <GraphqlProductNotAvailableModal
                    isOpen={showProductUnavailable && zipcode}
                    onClose={() => {
                        setShowCalendar(false);
                        setShowProductUnavailable(false);
                    }}
                    onNavigate={handleProductNotAvailableNavigate}
                    productName={name}
                    brand={brand['domain-name']}
                    trackEvent={trackEvent}
                    productNotAvailable={productNotAvaialble}
                    isFromQuickview
                    zipcode={zipcode}
                    selectedSku={calendarData?.selectedSku}
                />
            )}

            {showCalendar && (
                <GraphqlCalendarContainer
                    // see calendarData definition for details
                    isOpen={showCalendar}
                    close={() => setShowCalendar(false)}

                    isQuickviewOrigin
                    quickviewSelectDate={handleSelectDate}

                    selectedItemId={calendarData.selectedItemId}
                    zipCode={calendarData.zipCode}
                    productDeliveryType={calendarData.productDeliveryType}
                    setCalendarDataLoadingFlag={calendarData.setCalendarDataLoadingFlag}
                    brandId={calendarData.brandId}

                    partNumber={calendarData.partNumber}
                    productName={calendarData.productName}
                    personalization={calendarData.personalization}
                    selectedSku={calendarData.selectedSku}
                    isFlowersSubscriptionFeatureEnabled={calendarData.isFlowersSubscriptionFeatureEnabled}
                    isFlowersTempV1SubscriptionFeatureEnabled={calendarData.isFlowersTempV1SubscriptionFeatureEnabled}
                    enableMinicart={calendarData.enableMinicart}
                    handleMiniCartModalClick={calendarData.handleMiniCartModalClick}
                    handleDeliveryAvailability={calendarData.handleDeliveryAvailability}
                    ageDetails={calendarData.ageDetails}
                    hasWine={calendarData.hasWine}
                    quickViewLocationType={locationType?.name}

                    categoryId={categoryId}
                    categoryPath={categoryPath}
                    categoryName={categoryName}
                />
            )}

        </Grid>
    );
};

DeliverySelectors.propTypes = {
    data: object.isRequired,
    brand: object.isRequired,
    trackEvent: func.isRequired,
    setUserSubmittedProductFilterZipcode: func.isRequired,

    locationTypeOptions: arrayOf(shape({
        name: string.isRequired,
    })).isRequired,
    locationType: shape({ name: string.isRequired }).isRequired,
    setLocationType: func.isRequired,

    zipcode: string.isRequired,
    setZipcode: func.isRequired,
    validateProductFilterZipcode: func.isRequired,
    clearValidatedZipcode: func.isRequired,
    isProductFilterZipcodeValid: bool, // not marked required to allow null

    calendarData: object.isRequired,

    // empty state pointers for validation in handleCalendarClick
    productSelectorInitialState: object.isRequired,
    zipcodeInitialState: string.isRequired,

    // local state error handling
    error: object.isRequired,
    accountError: func.isRequired,
    resolveError: func.isRequired,
    errorTypes: object.isRequired,

    date: string.isRequired,
    setDate: func.isRequired,
    setAddToCartData: func.isRequired,

    ageDetails: shape({
        show: bool.isRequired,
        hasResponded: bool.isRequired,
        birthDay: string.isRequired,
        birthMonth: string.isRequired,
        birthYear: string.isRequired,
        minAge: string.isRequired,
    }).isRequired,
    setAgeDetails: func.isRequired,

    showProductUnavailable: bool.isRequired,
    setShowProductUnavailable: func.isRequired,
    categoryId: string,
    categoryPath: string,
    categoryName: string,
};

DeliverySelectors.defaultProps = {
    isProductFilterZipcodeValid: null,
    categoryId: '',
    categoryPath: '',
    categoryName: '',
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(trackEventAction, dispatch),
    setUserSubmittedProductFilterZipcode: bindActionCreators(setUserSubmittedProductFilterZipcodeAction, dispatch),
});

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(DeliverySelectors);
