/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { object, string } from 'prop-types';

const styles = ({ palette, typography }) => ({
    sorryMessage: {
        fontSize: '15px',
        color: palette.cms?.errorZipVerifyPDP,
        fontFamily: typography?.fontFamily,
        textAlign: 'center',
        margin: '12px 0px 0px 0px',
        padding: '5px 10px',
    },
    outOfStockMessage: {
        fontSize: '15px',
        color: palette.cms?.errorZipVerifyPDP,
        fontFamily: typography?.fontFamily,
        textAlign: 'center',
        margin: 0,
    },
    errorMessageContainer: {
        color: '#DF3030',
        width: '100%',
        padding: '0 15px 20px',
    },
});

const getErrorMessage = (error) => {
    if (error && error !== 'MAX_CART_SIZE_REACHED') {
        return 'This item is no longer available';
    }
    return '';
};

const AddToCartErorMessage = ({ error, classes }) => {
    const addToCartError = getErrorMessage(error);
    return (
        <>
            {addToCartError !== ''
                ? (
                    <div className={classes.errorMessageContainer}>
                        <p className={classes.sorryMessage}>We&apos;re sorry.</p>
                        <p className={classes.outOfStockMessage}>{addToCartError}</p>
                    </div>
                )
                : null}
        </>
    );
};

AddToCartErorMessage.propTypes = {
    error: string,
    classes: object.isRequired,
};

AddToCartErorMessage.defaultProps = {
    error: '',
};

export default withStyles(styles)(AddToCartErorMessage);
