/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
    object, string, shape, func, number, arrayOf,
} from 'prop-types';
import QuickviewDataWrapper from './partials/DataWrapper';
import noop from '../../../../../helpers/noop';
import { getPresentationFamily } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const useStyle = makeStyles((theme) => ({
    wrapper: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
    },
    productQuickview: {
        ...theme.typography.button,
        width: '50%',
        zIndex: 1,
        color: theme.palette.common.white,
        opacity: 0,
        backgroundColor: theme.palette.colorPrimary,
        letterSpacing: '1.5px',

        // font control
        fontSize: '1em',
        [theme.breakpoints.down(1440)]: {
            top: '35%',
        },
        [theme.breakpoints.down(1240)]: {
            fontSize: '.7em',
        },
        '@media only screen and (max-width: 950px)': {
            fontSize: '.5em',
            top: '25%',
            width: '60%',
        },
        '@media only screen and (max-width: 1240px) and  (min-width:950px)': {
            width: '60%',
        },
        // positioning
        position: 'absolute',
        top: '40%',
        left: '25%',

        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.common.white,
            backgroundColor: theme.palette.colorPrimary,
            opacity: 1,
        },
    },
    quickViewBottomBtn: {
        top: '70%',
    },
}));

/**
 * The parent, DesktopSimpleProduct has an onHover that affects
 * state causing re-renders. Use React.memo to prevent some unnecessary work.
 */
const ProductQuickview = React.memo((props) => {
    const [show, setShow] = useState(false);
    const {
        brand,
        partNumber,
        linkPackage,
        onSetShow,
        trackEvent,
        productName,
        categoryName,
        categoryId,
        location,
        presentation_family,
        imageHeight,
        dynamicPrice,
    } = props;
    const [miniCartModalOpen, setMiniCartModalOpen] = React.useState(false);

    const classes = useStyle();
    /**
     * @description Callback interface to provide the ability to perform actions when toggling the dialog.
     * @param {boolean} setTo
     */
    const handleProductQuickviewSetShow = (setTo) => {
        onSetShow(setTo);
        setShow(setTo);
    };

    const nestedOnClickEventHandler = (event) => {
        /**
         * Since this is nested within a link component, prevent default but
         * allow clicks of links if they are within quickview based on
         * id.
         */
        if (event.target.id !== linkPackage.id) {
            event.preventDefault();
        }
    };

    const quickViewBtnStyled = {};

    if (typeof imageHeight?.current?.clientHeight !== 'undefined' && presentation_family === 'food') {
        quickViewBtnStyled.top = `calc(${imageHeight.current.clientHeight}px - 80px)`;
    }

    return (
        <>
            <QuickviewDataWrapper
                brand={brand}
                show={show}
                setShow={handleProductQuickviewSetShow}
                partNumber={partNumber}
                linkPackage={linkPackage}
                categoryId={categoryId}
                categoryName={categoryName}
                location={location}
                // need to pass nestedOnClickEventHandler down to perform prevention oen more time
                nestedOnClickEventHandler={nestedOnClickEventHandler}
                miniCartModalOpen={miniCartModalOpen}
                setMiniCartModalOpen={setMiniCartModalOpen}
                dynamicPrice={dynamicPrice}
            />
            <span id={`quickview_button_${partNumber}`} className={classes.wrapper}>
                <Button
                    className={`${classes.productQuickview}`}
                    style={quickViewBtnStyled}
                    onClick={(event) => {
                        nestedOnClickEventHandler(event);
                        if (typeof trackEvent !== 'undefined') {
                            trackEvent({
                                eventCategory: 'Collection Page',
                                eventAction: 'Quickview Category Click',
                                eventLabel: productName,
                            });
                        }
                        handleProductQuickviewSetShow(true);
                        linkPackage.handleLinkOnClick();
                    }}
                >QUICK VIEW
                </Button>
            </span>

        </>
    );
});

ProductQuickview.propTypes = {
    partNumber: string.isRequired,
    presentation_family: string.isRequired,
    brand: shape({
        domain: string.isRequired,
    }).isRequired,
    linkPackage: shape({
        id: string.isRequired,
        // information for to param in Link component
        sendLinkTo: shape({
            pathname: string.isRequired,
            search: string,
            state: object,
        }).isRequired,
        // tracking handler for onclick in link component
        handleLinkOnClick: func,
    }).isRequired,
    onSetShow: func, // fires during setShow handler
    trackEvent: func.isRequired,
    productName: string.isRequired,
    categoryName: string.isRequired,
    categoryId: string.isRequired,
    location: shape({
        pathname: string.isRequired,
    }).isRequired,
    imageHeight: shape({
        current: shape({
            clientHeight: number,
        }),
    }),
    dynamicPrice: shape({
        priceRule: arrayOf(string),
    }),

};

ProductQuickview.defaultProps = {
    onSetShow: noop,
    imageHeight: {},
    dynamicPrice: {},
};
const mapStateToProps = (state) => ({
    presentation_family: getPresentationFamily(state),
});
const enhance = compose(
    connect(mapStateToProps, null),
);
export default enhance(ProductQuickview);
