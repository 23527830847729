/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import QueryString from 'qs';
import { useLocation } from 'react-router';

const useIsPlaPage = () => {
    const location = useLocation();
    const urlParams = QueryString.parse(location.search.slice(1));
    if (urlParams?.adtype?.includes('pla')) {
        return true;
    }
    return false;
};

export default useIsPlaPage;
