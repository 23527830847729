/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useLazyQuery } from '@apollo/client';
import { node, string } from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getBrandName } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import findContent from '../../../../gql/queries/findContent';

const SisterBrandProductContext = React.createContext(null);

const SisterBrandProduct = ({ children, categoryId }) => {
    const isSisterBrandTreatmentEnabled = useSelector(getFeatureFlag('is-sister-brand-product-treatment-enabled'));
    const brand = useSelector(getBrandName);
    const query = `\\"$or\\": [{ \\"title\\": \\"Default\\" }, { \\"category_id\\": \\"${categoryId}\\" }]`;
    const SISTER_BRAND_QUERY = findContent({
        brand,
        contentType: 'sister_brand_product_treatment',
        query,

    });

    const [getSisterBrandProps, {
        data: sisterPropData,
    }] = useLazyQuery(SISTER_BRAND_QUERY);

    useEffect(() => {
        if (isSisterBrandTreatmentEnabled) {
            getSisterBrandProps();
        }
    }, []);

    const data = sisterPropData?.findContent?.content?.entries || [];

    const defaultEntry = data?.find?.((entry) => entry?.title === 'Default') || null;
    const currentCategory = data?.find?.((entry) => entry?.category_id === categoryId) || null;

    return (
        <SisterBrandProductContext.Provider value={currentCategory || defaultEntry || null}>
            {children}
        </SisterBrandProductContext.Provider>
    );
};

export default SisterBrandProduct;

SisterBrandProduct.propTypes = {
    children: node.isRequired,
    categoryId: string,
};

SisterBrandProduct.defaultProps = {
    categoryId: '',
};

export { SisterBrandProductContext };
