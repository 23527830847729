/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SkeletonStyles from './SkeletonStyles';

const styles = (theme) => ({
    // Import Skeleton layout
    ...SkeletonStyles,

    // Page Elements
    quickviewGrid: {
        display: 'grid',
        gridTemplateColumns: '38.5% 58.5%',
        gridGap: '3%',
        height: 'calc(100% - 16px)', // less the px for the close button

        '&--quickview-block': {
            width: '355px',
            height: '390px',
        },

        [theme.breakpoints.down(1015)]: {
            '&--quickview-block': {
                width: '100%',
                height: '280px',
            },
        },

        // stacked
        [theme.breakpoints.down(830)]: {
            display: 'block',
            gridTemplateColumns: 'unset',
            gridGap: '0',
            height: '100%',

            '&--quickview-stacked': {
                height: 'calc(100% - 280px)',
                paddingTop: '1em',
            },
        },

        '&--quickview-title': {
            height: '10%',
            width: '60%',
            marginBottom: '1em',
        },
        '&--quickview-options': {
            height: '20%',
            width: '100%',
            marginBottom: '1.5em',
        },
        '&--quickview-linebreak': {
            width: '100%',
            borderTop: '1px solid #dbdbdb',
            borderLeft: 'none',
            marginBottom: '1.5em',
        },
        '&--quickview-subtitle': {
            height: '7%',
            width: '40%',
            marginBottom: '1em',
        },
        '&--quickview-delivery': {
            height: '15%',
            width: '100%',
            marginBottom: '1em',
        },
        '&--quickview-submit': {
            height: '12%',
            width: '100%',
            marginBottom: '1em',
        },
    },
});

const QuickviewSkeleton = ({ classes }) => (

    <div data-testid="quickview_skeleton" className={classes.quickviewGrid}>
        <div>
            <div className={`${classes.quickviewGrid}--quickview-block ${classes.textFW} ${classes.shimmer}`} />
        </div>
        <div className={`${classes.quickviewGrid}--quickview-stacked`}>
            <div className={`${classes.quickviewGrid}--quickview-title ${classes.textMD} ${classes.shimmer}`} />
            <div className={`${classes.quickviewGrid}--quickview-options  ${classes.textLG} ${classes.shimmer}`} />
            <hr className={` ${classes.quickviewGrid}--quickview-linebreak ${classes.shimmer}`} />
            <div className={`${classes.quickviewGrid}--quickview-subtitle  ${classes.textMD} ${classes.shimmer}`} />
            <div className={`${classes.quickviewGrid}--quickview-delivery  ${classes.textLG} ${classes.shimmer}`} />
            <div className={`${classes.quickviewGrid}--quickview-submit  ${classes.textLG} ${classes.shimmer}`} />
        </div>
    </div>

);

QuickviewSkeleton.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(QuickviewSkeleton);
