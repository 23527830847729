/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import QueryString from 'qs';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { getFeatureFlag } from '../../../state/ducks/App/ducks/Config/Config-Selectors';

const useIsDisplayChannel = () => {
    const location = useLocation();
    const isPlaPDPDisplay = useSelector(getFeatureFlag('is-pla-pdp-display-channel-enabled'));
    const urlParams = QueryString.parse(location.search.slice(1));
    if (urlParams?.utm_medium?.includes('display') && isPlaPDPDisplay) {
        return true;
    }
    return false;
};

export default useIsDisplayChannel;
