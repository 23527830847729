/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';

import {
    bool, func, string, shape, object, arrayOf,
} from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { Close } from '@material-ui/icons';
import mbpLogger from 'mbp-logger';
import { GRAPHQL_ENV } from '../../../../../../gql';
import {
    getLocationType,
    getValidatedZipcode,
    getIsProductFilterZipcodeValid,
    getUserSubmittedProductFilterZipcode,
} from '../../../../../../../state/ducks/App/App-Selectors';
import {
    clearValidatedZipcode as clearValidatedZipcodeAction,
    validateProductFilterZipcode as validateProductFilterZipcodeAction,
    setBrandMovieSkus as setBrandMovieSkusAction,
    setAgeVerifyFlagInvalid,
    resetWineData,
} from '../../../../../../../state/ducks/App/App-Actions';
import { trackEvent as trackEventAction } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import {
    addToCartNewCartServices, clearAddToCartError,
} from '../../../../../../../state/ducks/AddToCart/AddToCart-Actions';
import findProductPageByPartNumber from '../../../../../../gql/queries/findProductPageByPartNumber';
import QuickviewSkeleton from '../../../../GraphqlSkeletonComponents/QuickviewSkeleton';
import QuickviewDialogContent from './QuickviewDialogContent';
import noop from '../../../../../../helpers/noop';
import FoodQuickViewContent from '../../../../GraphqlProductPage/Partials/GraphqlPDPSkus/GraphqlFoodQuickView/FoodQuickViewContent';
import { getPresentationFamily, getFeatureFlags } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';

import MiniCart from '../../../../../Checkout/containers/CheckoutFood/components/Partials/MiniCart/MiniCart';
import { getAddToCartLoaderStatus } from '../../../../../../../state/ducks/Common/Common-Selectors';
import SiteLoader from '../../../../../../pages/Checkout/containers/Common/SiteLoader/SiteLoader';

const useStyle = makeStyles((theme) => ({

    DialogContainerRoot: {
        height: '100%',
        width: '100%',
        '& .MuiDialog-container': {
            '& .MuiDialog-paper': {
                'flex-direction': 'row',
            },
        },
    },

    QuickviewCardContainer: {
        boxSizing: 'border-box',

        // popup sizing
        width: '950px',

        [theme.breakpoints.down(1015)]: {
            maxHeight: '700px',
            height: 'auto',
            width: '98vw',
        },
        [theme.breakpoints.down(768)]: {
            overflowY: 'auto',
        },
        '@media screen and (max-height: 699px)': {
            overflowY: 'auto',
        },
        padding: '1em 1em',
        paddingBottom: '1.5em',
    },

    QuickviewTopActions: {
        height: '0',
        [theme.breakpoints.down(1015)]: {
            paddingBottom: '20px',
        },
    },

    QuickviewCloseButton: {
        transition: 'unset',
        padding: '0',
        marginTop: '5px',
        marginRight: '-10px',
        paddingBottom: '10px',

        '&:hover': {
            cursour: 'pointer',
            backgroundColor: 'transparent',
        },
    },
}));

const QuickviewDataWrapper = (props) => {
    const {
        show,
        setShow,
        brand,
        partNumber,
        reduxLocationType,
        isProductFilterZipcodeValid,
        clearValidatedZipcode,
        validateProductFilterZipcode,
        actionAddToCart,
        linkPackage,
        nestedOnClickEventHandler,
        setBrandMovieSkus,
        userSubmittedProductFilterZipcode,
        trackEvent,
        presentation_family,
        resetAgeVerificationFlag,
        resetWineDataAll,
        categoryName,
        categoryId,
        location,
        featureFlags,
        showLoadingMessage,
        miniCartModalOpen,
        setMiniCartModalOpen,
        dynamicPrice,
        callClearAddToCartError,
    } = props;

    if (!show && !miniCartModalOpen) return null;
    const classes = useStyle();
    /**
     * This query is done on mouseover of the product image on category page for
     * prefetching. Way more information than we need for quickview but the data
     * is already here lets skip a call all together and used this
     * cached response.
     */
    const QUICKVIEW_DIALOG_QUERY = findProductPageByPartNumber(brand.domain, partNumber);
    const { loading, error, data } = useQuery(QUICKVIEW_DIALOG_QUERY);

    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: QUICKVIEW_DIALOG_QUERY,
            component: 'ProductQuickview.DataWrapper.js',
            message: 'Error prefetching PDP.',
            env: GRAPHQL_ENV,
            error,
        });
    }

    const hasData = data?.findProductPageByPartNumber?.product;
    const dataWithSortedSkus = {
        ...data?.findProductPageByPartNumber?.product,
        productSkus: data?.findProductPageByPartNumber?.product?.productSkus?.length > 1
            ? [...data.findProductPageByPartNumber.product.productSkus].sort((a, b) => a.prices?.[0]?.value - b.prices?.[0]?.value)
            : data?.findProductPageByPartNumber?.product?.productSkus,
    };
    const shouldRenderContent = !loading && !error && hasData;
    const hasWine = dataWithSortedSkus?.productSkus?.[0]?.ageVerifyFlag === 'true';
    let isFoodBrand = presentation_family === 'food';

    const getCrossBrand = featureFlags?.['which-cross-merch-brands-list'][dataWithSortedSkus?.brandId];

    if (getCrossBrand) {
        isFoodBrand = getCrossBrand === 'food';
    }

    // Get The Minimum Price of Product
    const priceArray =  data?.findProductPageByPartNumber?.product?.productSkus?.map((item) => {
        const priceValue = item?.prices?.map((price) => price?.value);
        return priceValue?.[0];
    });
    const minimumPrice = Math.min.apply(null, priceArray);
    useEffect(() => {
        if (hasWine) {
            resetAgeVerificationFlag(false);
            resetWineDataAll();
        }
    }, [data]);

    useEffect(() => {
        if (!hasData?.name || miniCartModalOpen || !show) return;
        trackEvent({
            eventName: 'quickview_modal',
            eventCategory: 'Product Page',
            eventAction: 'Quickview PDP View',
            eventLabel: dataWithSortedSkus?.name,
            products: [
                {
                    brand: dataWithSortedSkus?.brandId || '',
                    categoryPath: location?.pathname || '',
                    partNumber: dataWithSortedSkus?.partNumber || '',
                    categoryId,
                    categoryName,
                    id: dataWithSortedSkus?.legacyId || '',
                    name: dataWithSortedSkus?.name || '',
                    price: minimumPrice ||  '',
                    position: 1,
                },
            ],
        });
    }, [hasData?.name]);

    const renderMiniCart = () => {
        if ((featureFlags['is-mini-cart-enabled'] || featureFlags['is-mini-cart-v2-enabled']) && isFoodBrand) {
            return (
                <>
                    {(showLoadingMessage && !miniCartModalOpen)
                    && (
                        <SiteLoader />
                    )}

                    <MiniCart
                        handleMiniCartModalClick={() => {
                            setMiniCartModalOpen(!miniCartModalOpen);
                        }}
                        minicartModalOpen={miniCartModalOpen}
                        product={dataWithSortedSkus}
                        presentation_family="food"
                        isQuickView
                    />

                </>
            );
        }
        return null;
    };

    return (
        <>
            {isFoodBrand && renderMiniCart()}
            <Dialog
                open={show}
                onClick={nestedOnClickEventHandler}
                onClose={() => {
                    setShow(false);
                    callClearAddToCartError();
                }}

                maxWidth="lg"
                classes={{
                    root: classes.DialogContainerRoot,
                }}
            >
                <Card
                    data-testid="quickview_content_container"
                    variant="outlined"
                    className={classes.QuickviewCardContainer}
                >

                    <DialogActions className={classes.QuickviewTopActions}>
                        <IconButton
                            data-testid="quickview_close_button"
                            className={classes.QuickviewCloseButton}
                            onClick={() => {
                                setShow(false);
                                callClearAddToCartError();
                            }}
                        >
                            <Close />
                        </IconButton>
                    </DialogActions>

                    {loading && <QuickviewSkeleton />}

                    {(shouldRenderContent && !isFoodBrand)
                    && (
                        <QuickviewDialogContent
                            data={data}
                            setShow={setShow}
                            reduxLocationType={reduxLocationType}
                            isProductFilterZipcodeValid={isProductFilterZipcodeValid}
                            clearValidatedZipcode={clearValidatedZipcode}
                            validateProductFilterZipcode={validateProductFilterZipcode}
                            actionAddToCart={actionAddToCart}
                            linkPackage={linkPackage}
                            setBrandMovieSkus={setBrandMovieSkus}
                            trackEvent={trackEvent}
                            userSubmittedProductFilterZipcode={userSubmittedProductFilterZipcode}
                            categoryId={categoryId}
                            categoryName={categoryName}
                            categoryPath={location?.pathname || ''}
                        />
                    )}

                    {shouldRenderContent && isFoodBrand && (
                        <Grid container>
                            <FoodQuickViewContent
                                quickViewData={dataWithSortedSkus?.productSkus?.[0]}
                                product={dataWithSortedSkus}
                                domain={brand?.domain}
                                selectId={dataWithSortedSkus?.legacyId}
                                selectedName={dataWithSortedSkus?.name}
                                partNumber={dataWithSortedSkus?.partNumber}
                                shortDescription={dataWithSortedSkus?.shortDescription}
                                image={dataWithSortedSkus?.image}
                                productContentSku={dataWithSortedSkus?.productSkus}
                                quickViewLongDesc={dataWithSortedSkus?.longDescription || dataWithSortedSkus?.productSkus?.[0]?.longDescription}
                                productUrl={dataWithSortedSkus?.seo?.url}
                                brand={brand}
                                addToCartHide
                                trackEvent={trackEvent}
                                hasWine={hasWine}
                                handleMiniCartModalClick={() => {
                                    setShow(false);
                                    setMiniCartModalOpen(!miniCartModalOpen);
                                }}
                                enableMiniCart={(featureFlags['is-mini-cart-enabled'] || featureFlags['is-mini-cart-v2-enabled'])}
                                productSkus={dataWithSortedSkus?.productSkus}
                                dynamicPrice={dynamicPrice}
                                categoryId={categoryId}
                                categoryName={categoryName}
                                categoryPath={location?.pathname || ''}
                            />
                        </Grid>

                    )}

                </Card>
            </Dialog>
        </>
    );
};

QuickviewDataWrapper.propTypes = {

    // parent state
    show: bool.isRequired,
    setShow: func.isRequired,
    nestedOnClickEventHandler: func,

    brand: shape({
        domain: string.isRequired,
    }).isRequired,
    partNumber: string.isRequired,

    reduxLocationType: string.isRequired,

    // zipcode
    isProductFilterZipcodeValid: bool.isRequired,
    clearValidatedZipcode: func.isRequired,
    validateProductFilterZipcode: func.isRequired,
    userSubmittedProductFilterZipcode: string,

    setBrandMovieSkus: func.isRequired, // redux
    actionAddToCart: func.isRequired, // redux
    linkPackage: shape({
        id: string.isRequired,
        // information for to param in Link component
        sendLinkTo: shape({
            pathname: string.isRequired,
            search: string,
            state: object,
        }).isRequired,
        // tracking handler for onclick in link component
        handleLinkOnClick: func,
    }).isRequired,
    // tracking
    trackEvent: func.isRequired,
    resetAgeVerificationFlag: func.isRequired,
    resetWineDataAll: func,
    presentation_family: string.isRequired,
    categoryName: string.isRequired,
    categoryId: string.isRequired,
    location: shape({
        pathname: string.isRequired,
    }).isRequired,
    featureFlags: shape({
        'is-mini-cart-enabled': bool,
    }),
    showLoadingMessage: bool,
    miniCartModalOpen: bool,
    setMiniCartModalOpen: func,
    dynamicPrice: shape({
        priceRule: arrayOf(string),
    }),
    callClearAddToCartError: func.isRequired,
};

QuickviewDataWrapper.defaultProps = {
    nestedOnClickEventHandler: noop,
    userSubmittedProductFilterZipcode: '',
    resetWineDataAll: noop,
    showLoadingMessage: false,
    featureFlags: {},
    miniCartModalOpen: false,
    setMiniCartModalOpen: noop,
    dynamicPrice: {},
};

const mapStateToProps = (state) => ({
    reduxLocationType: getLocationType(state),
    presentation_family: getPresentationFamily(state),
    // zipcode
    validatedZipcode: getValidatedZipcode(state),
    userSubmittedProductFilterZipcode: getUserSubmittedProductFilterZipcode(state),
    isProductFilterZipcodeValid: getIsProductFilterZipcodeValid(state),

    featureFlags: getFeatureFlags(state),
    showLoadingMessage: getAddToCartLoaderStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
    clearValidatedZipcode: bindActionCreators(clearValidatedZipcodeAction, dispatch),
    validateProductFilterZipcode: bindActionCreators(validateProductFilterZipcodeAction, dispatch),
    actionAddToCart: bindActionCreators(addToCartNewCartServices, dispatch),
    resetWineDataAll: bindActionCreators(resetWineData, dispatch),
    setBrandMovieSkus: bindActionCreators(setBrandMovieSkusAction, dispatch),
    trackEvent: bindActionCreators(trackEventAction, dispatch),
    resetAgeVerificationFlag: bindActionCreators(setAgeVerifyFlagInvalid, dispatch),
    callClearAddToCartError: bindActionCreators(clearAddToCartError, dispatch),

});

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(QuickviewDataWrapper);
